<template>
  <section>
    <BillAddEdit
      :es-id="addEdit.esId"
      :stepper-mode="addEdit.stepperMode"
      :stepper-step="addEdit.stepperStep"
      :primary-key="addEdit.primaryKey"
      :primary-key-lock="addEdit.primaryKeyLock"
      :raw-data="addEdit.rawData"
      :rentPackages="addEdit.rentPackages"
      :rentalEnabled="hasRentalActive"
      :busy="addEdit.busy"
      @refresh="$store.dispatch('fsTable/fetchData')"
    />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div v-if="isShowOnlyPageTitle">
            <oto-page-title :title="pageTitle" />
          </div>

          <TitlePlus
            v-else
            :title="pageTitle"
            @plus="add"
            :hide-plus="$acl.canNotCreate('Fleet Pricing')"
          />

          <div class="flex md:justify-end">
            <DateRangePicker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>

        <div class="summary-card-container">
          <SummaryCard
            :title="$t('components.billingPlanManagement.summary.totalRevenue')"
            :value="`${symbol} ${indexMetaData.summary.total_revenue}`"
            variant="gray"
          />
        </div>
      </div>
      <div
        v-if="$acl.canNotView('Fleet Pricing')"
        class="py-5 font-bold text-center text-gray-600"
      >
        {{ $t('components.acl.doNotHavePermission') }}
      </div>

      <FSTable
        v-else
        :fst-id="`pricingIndex`"
        :headers="getTableHeaders"
        :qso="qso"
        :endpoint="endpoint"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="`pricingIndex`"
              :slot-width="slotWidth"
              :options="getFilteredItems"
            />
          </keep-alive>
        </template>

        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" text-fallback-always>
                <FSTableRowItem :text="item.id" :truncate="-5" />

                <FSTableRowItem>
                  <div
                    @click="onOpenES({ primaryKey: item.id })"
                    class="font-semibold text-blue-700 cursor-pointer"
                  >
                    {{ item.name }}
                  </div>
                </FSTableRowItem>

                <FSTableRowItem>
                  {{ item.tax_plan ? item.tax_plan.name : '--' }}
                </FSTableRowItem>

                <FSTableRowItem>
                  {{ item.fleet ? item.fleet.name : '--' }}
                </FSTableRowItem>

                <FSTableRowItem>
                  {{ getCurrencySymbol(item) }} {{ item.max_billing_per_ride }}
                </FSTableRowItem>

                <FSTableRowItem>
                  {{ getCurrencySymbol(item) }}
                  {{ item.vehicle_reservation_fees }} </FSTableRowItem
                ><FSTableRowItem>
                  {{ getCurrencySymbol(item) }}
                  {{ item.default_unit_fees }}
                </FSTableRowItem>

                <FSTableRowItem>
                  <XStatus
                    :variant="item.status_active ? 'green' : 'gray'"
                    :text="item.status_active ? 'Active' : 'Inactive'"
                    size="small"
                  />
                </FSTableRowItem>

                <FSTableRowItem>
                  <div class="flex items-center">
                    <oto-edit-icon @click="onOpenES({ primaryKey: item.id })" />
                    <MoreActionsDropdown
                      :currentVal="item.status_active"
                      :onOffEndpoint="getActionsEndpoint(item.id)"
                      actionOnKey="status_active"
                      actionOffKey="status_active"
                      :actionOnVal="true"
                      :actionOffVal="false"
                      @actionOn="item.status_active = true"
                      @actionOff="item.status_active = false"
                    />
                  </div>
                </FSTableRowItem> </FSTableRow
            ></template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem>
                  <div
                    @click="onOpenES({ primaryKey: item.id })"
                    class="font-semibold text-blue-700 cursor-pointer"
                  >
                    {{ item.name }}
                  </div>
                </FSTableRowItem>
                <FSTableRowItem>
                  {{ item.tax_plan ? item.tax_plan.name : '--' }}
                </FSTableRowItem>
                <FSTableRowItem>
                  {{ item.fleet ? item.fleet.name : '--' }}
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.billingPlanManagement.table.columns.id')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.id | onlyLastFive }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.billingPlanManagement.table.columns.maxBillingPerRide'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ getCurrencySymbol(item) }}
                      {{ item.max_billing_per_ride }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.billingPlanManagement.table.columns.vehicleReservationCharge'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ getCurrencySymbol(item) }}
                      {{ item.vehicle_reservation_fees }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.billingPlanManagement.table.columns.defaultUnitFees'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ getCurrencySymbol(item) }}
                      {{ item.default_unit_fees }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.billingPlanManagement.table.columns.status'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <XStatus
                        :variant="item.status_active ? 'green' : 'gray'"
                        :text="item.status_active ? 'Active' : 'Inactive'"
                        size="small"
                      />
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.billingPlanManagement.table.columns.actions'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <div class="flex items-center">
                        <oto-edit-icon
                          @click="onOpenES({ primaryKey: item.id })"
                        />
                        <MoreActionsDropdown
                          :currentVal="item.status_active"
                          :onOffEndpoint="getActionsEndpoint(item.id)"
                          actionOnKey="status_active"
                          actionOffKey="status_active"
                          :actionOnVal="true"
                          :actionOffVal="false"
                          @actionOn="item.status_active = true"
                          @actionOff="item.status_active = false"
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
  </section>
</template>

<script>
// import BaseLayout from '@/views/shared/BaseLayout'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import XStatus from '@/components/badge/XStatus'
import MoreActionsDropdown from '@/components/dropdown/MoreActionsDropdown'

import { BillingPlanConfig } from '@/config/BillingPlanConfig'

import { mapGetters } from 'vuex'
import SummaryCard from '@/components/cards/SummaryCard'

import BillAddEdit from '@/views/pricing/add-edit-bill/BillAddEdit.vue'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import { useEndpoints } from '@/composables'
import { vehicleTypesForOrg } from '@/utils'
export default {
  name: 'BillingPlans',

  components: {
    // BaseLayout,
    ContentSection,
    TitlePlus,
    OtoEditIcon,
    DateRangePicker,
    SummaryCard,
    XStatus,
    MoreActionsDropdown,
    BillAddEdit,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
    OtoPageTitle: () => import('@/components/ui/OtoPageTitle'),
  },
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    endpoint: {
      type: String,
      default: BillingPlanConfig.api.index,
    },
    qso: {
      type: Object,
      default: () => ({ append: '', prepend: '?' }),
    },
    filterItems: {
      type: Array,
      default: () => [],
    },
    isShowOnlyPageTitle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    hasRentalActive() {
      return this.$org?.subscription?.rental_enabled
    },
    getFilteredItems() {
      if (this.filterItems.length) {
        if (this.filterItems.length === this.filterOptions.length) {
          let propFilterItems = this.filterItems
          propFilterItems.push({}) //-for updating filteritems into rendering moment
          return propFilterItems
        } else {
          return this.filterItems
        }
      } else {
        return this.filterOptions
      }
    },
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          total_revenue: 0,
        },
        count: {
          total: 0,
        },
      },
      windowWidth: window.innerWidth,
      opened: [],
      symbol: '$',
      indexDataEndpoint: BillingPlanConfig.api.index,
      vehicleTypes: [],
      tableHeaders: [
        {
          text: `${this.$t(
            'components.billingPlanManagement.table.columns.id'
          )}`,
          width: '7%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.billingPlanManagement.table.columns.billingName'
          )}`,
          width: '12%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.billingPlanManagement.table.columns.taxName'
          )}`,
          width: '7%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.billingPlanManagement.table.columns.assignedFleet'
          )}`,
          width: '12%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.billingPlanManagement.table.columns.maxBillingPerRide'
          )}`,
          width: '12%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.billingPlanManagement.table.columns.vehicleReservationCharge'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.billingPlanManagement.table.columns.defaultUnitFees'
          )}`,
          width: '13%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.billingPlanManagement.table.columns.status'
          )}`,
          width: '10%',
          sort: 'is_active',
        },
        {
          text: `${this.$t(
            'components.billingPlanManagement.table.columns.actions'
          )}`,
          width: '5%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: `${this.$t(
            'components.billingPlanManagement.table.columns.billingName'
          )}`,
          width: '40%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.billingPlanManagement.table.columns.taxName'
          )}`,
          width: '20%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.billingPlanManagement.table.columns.assignedFleet'
          )}`,
          width: '30%',
          sort: null,
        },
      ],
      filterOptions: [],
      addEdit: {
        // EdgeStack component
        esId: 'bill-add-edit',

        // XStepper component
        stepperMode: 'strict',
        stepperStep: 1,

        // Data
        busy: true,
        rawData: null,
        primaryKey: null,
        rentPackages: null,
      },
    }
  },
  async created() {
    const orgVehicleTypes = this?.$org?.vehicle_type || []
    orgVehicleTypes.forEach((t) =>
      this.vehicleTypes.push(vehicleTypesForOrg[t])
    )

    await this.generateFilterOptions()
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    // todo: remove the event listener
    // todo: create a refresh index data mixin ?
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'
    window.addEventListener(BillingPlanConfig.events.refresh, () => {
      this.getIndexData(this.getQueryString)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onApplyFilterDateRange,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    async generateFilterOptions() {
      let filters = []

      if (this.$acl.canView('fleets')) {
        let filterableFleets = []
        await this.$http.get('/dashboard/fleets/?dropdown').then(({ data }) => {
          if (data.data.length) {
            data.data.forEach((fleet) => {
              filterableFleets.push({ text: fleet.name, value: fleet.id })
            })
          }
        })
        filters.unshift({
          key: 'fleet',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        })
        this.filterOptions = filters
      } else {
        this.filterOptions = filters
      }
    },
    add: function() {
      this.onOpenES({ primaryKey: null })
    },

    // view: function(item) {
    //   console.log(item)
    //   EventBus.$emit(BillingPlanConfig.events.viewingData, item)
    //   this.$modal.show(BillingPlanConfig.events.viewingData)
    // },
    getSerializeRentData(pkData) {
      if (pkData.length > 0) {
        const modifiedPkData = pkData.map((item) => {
          return {
            id: item.id,
            period: parseFloat(item.period),
            cost: item.cost,
            typeDisabled: true,
            addDescriptionUI: item.description ? true : false,
            description: item.description,
          }
        })
        return modifiedPkData
      } else {
        return pkData
      }
    },
    async onOpenES({ primaryKey }) {
      if (typeof primaryKey === 'string') {
        this.addEdit.busy = true
        this.$edgeStack.open(this.addEdit.esId)

        const bReq = this.$http.get(BillingPlanConfig.api.details(primaryKey))
        const rentPackageReq = this.$http.get(
          useEndpoints.pricingPlan.rentPackages(primaryKey)
        )
        const requests = [bReq, rentPackageReq]

        await this.$http
          .all(requests)
          .then(
            this.$http.spread((...responses) => {
              let data = {}
              const [bRes, rentPackagesRes] = responses
              console.log(bRes.data, 'bRes')

              data = { ...bRes.data }

              this.addEdit.stepperMode = 'free'

              this.addEdit.busy = false
              this.addEdit.rawData = data
              this.addEdit.rentPackages = rentPackagesRes.data.map((item) => {
                return {
                  text: item.text,
                  value: item.value,
                  data: this.getSerializeRentData(item.data),
                }
              })
              this.addEdit.primaryKey = primaryKey
            })
          )
          .catch((err) => {
            console.warn({ err })

            this.$edgeStack.close(this.addEdit.esId)
            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: `Server Error`,
              text: 'Failed to retrieve data. Please try again.',
            })
          })

        return
      }

      this.addEdit.stepperMode = 'strict'

      this.addEdit.busy = false
      this.addEdit.rawData = null // for vehicle
      this.addEdit.primaryKey = null // for vehicle
      this.addEdit.primaryKeyLock = null // for lock

      this.$edgeStack.open(this.addEdit.esId)
      // this.$xStepper.navigate(this.addEdit.esId).to(4)
    },
    getActionsEndpoint(id) {
      return BillingPlanConfig.api.update(id)
    },
    getCurrencySymbol(item) {
      // console.log(item.fleet.country.currency_symbol)
      return item?.fleet?.country?.currency_symbol || this.symbol || '$'
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
