<template>
  <div ref="helpCenterContainer">
    <t-dropdown variant="smActions">
      <template
        v-slot:trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <div
          class="flex justify-center items-center px-4 border-l cursor-pointer hover:bg-yellow-200 w-55px h-55px "
          aria-label="Help Center Menu"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <div class="relative ">
            <i class="fa-sharp fas fa-question text-gray-700 "></i>
          </div>
        </div>
      </template>

      <template v-slot:default="{ hide }">
        <div
          ref="dropdown"
          @click="hide()"
          class="py-1 mt-px bg-white rounded-md shadow-md font-normal"
        >
          <t-dropdown-item>
            <router-link to="/help/getting-started" target="_blank">
              <div class="flex  justify-between">
                <p class="font-normal">Getting Started</p>
                <div>
                  <i
                    class="fas fa-arrow-up-right-from-square text-blue-600 text-sm cursor-pointer"
                  ></i>
                </div>
              </div>
            </router-link>
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>
  </div>
</template>
<script>
export default {
  name: 'HelpCenterDropdownTrigger',
  components: {
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),
  },
  mounted() {
    document.addEventListener('click', this.autoCloseEventListener)
  },
  destroy() {
    document.removeEventListener('click', this.autoCloseEventListener)
  },
  methods: {
    autoCloseEventListener(e) {
      const helpCenterContainer = this.$refs.helpCenterContainer
      if (
        (typeof helpCenterContainer !== 'undefined' &&
          helpCenterContainer !== e.target &&
          !helpCenterContainer.contains(e.target)) ||
        e.target === this.$refs.dropdown
      ) {
        if (this.$refs.dropdown) {
          this.$refs.dropdown.click()
        }
      }
    },
  },
}
</script>
