var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item.component && !_vm.isItemHidden)?_c(_vm.item.component,_vm._b({tag:"component"},'component',_vm.item.props,false)):(_vm.item.header && !_vm.isItemHidden)?_c('div',_vm._b({staticClass:"vsm--header",class:_vm.item.class},'div',_vm.item.attributes,false),[_vm._v(" "+_vm._s(_vm.item.title)+" ")]):(!_vm.isItemHidden)?_c('div',_vm._g({staticClass:"vsm--item",class:[{ 'vsm--item_open': _vm.show }],on:{"mouseout":_vm.mouseOutEvent}},
    _vm.disableHover && _vm.isCollapsed
      ? { click: _vm.mouseOverEvent }
      : { mouseover: _vm.mouseOverEvent }
  ),[_c('sidebar-menu-link',{class:_vm.itemLinkClass,attrs:{"item":_vm.item,"attributes":_vm.item.attributes},nativeOn:{"click":function($event){return _vm.clickEvent.apply(null, arguments)}}},[(_vm.item.icon && !_vm.isMobileItem)?_c('sidebar-menu-icon',{attrs:{"icon":_vm.item.icon}}):_vm._e(),_c('transition',{attrs:{"name":"fade-animation","appear":_vm.isMobileItem}},[((_vm.isCollapsed && !_vm.isFirstLevel) || !_vm.isCollapsed || _vm.isMobileItem)?[_c('span',{staticClass:"vsm--title flex"},[_vm._v(_vm._s(_vm.$t(("components.dashboardNavigation." + (_vm.item.key))))+" "),(
              _vm.item.title === "Reward Zones" || _vm.item.title === "Delete Request"
            )?_c('img',{staticClass:"ml-1 w-5 h-5",attrs:{"src":require("@/assets/img/new.png")}}):_vm._e(),(
              _vm.item.title === "Pods" ||
                _vm.item.title === "Marketplace" ||
                _vm.item.title === "Topup"
            )?_c('img',{staticClass:"ml-1 w-8 h-4",attrs:{"src":require("@/assets/img/BETA.png")}}):_vm._e()])]:_vm._e()],2),((_vm.isCollapsed && !_vm.isFirstLevel) || !_vm.isCollapsed || _vm.isMobileItem)?[(_vm.item.badge)?_c('sidebar-menu-badge',{attrs:{"badge":_vm.item.badge}}):_vm._e(),(_vm.itemHasChild)?_c('div',{staticClass:"vsm--arrow",class:[
          { 'vsm--arrow_open': _vm.show },
          { 'vsm--arrow_slot': _vm.$slots['dropdown-icon'] } ]},[_vm._t("dropdown-icon")],2):_vm._e()]:_vm._e()],2),(_vm.itemHasChild)?[((_vm.isCollapsed && !_vm.isFirstLevel) || !_vm.isCollapsed || _vm.isMobileItem)?[_c('transition',{attrs:{"appear":_vm.isMobileItem,"name":"expand"},on:{"enter":_vm.expandEnter,"afterEnter":_vm.expandAfterEnter,"beforeLeave":_vm.expandBeforeLeave}},[(_vm.show)?_c('div',{staticClass:"vsm--dropdown",class:_vm.isMobileItem && 'vsm--dropdown_mobile-item',style:(_vm.isMobileItem && _vm.mobileItemStyle.dropdown)},[_c('div',{staticClass:"vsm--list"},_vm._l((_vm.item.child),function(subItem,index){return _c('sidebar-menu-item',{key:index,attrs:{"item":subItem,"level":_vm.level + 1,"show-child":_vm.showChild,"rtl":_vm.rtl,"is-collapsed":_vm.isCollapsed}},[_vm._t("dropdown-icon",null,{"slot":"dropdown-icon"})],2)}),1)]):_vm._e()])]:_vm._e()]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }