<template>
  <EdgeStack :id="esId">
    <template v-slot:header>
      <div class="pb-3 panel-title">{{ getTitleText }}</div>

      <XStepper
        :id="esId"
        :steps="stepperSteps"
        :mode="stepperMode"
        v-model="stepperCurrentStep"
        ref="xstepper"
      />
    </template>

    <template v-slot:footer>
      <XStepperNavigation
        v-if="isEditing"
        :id="esId"
        :steps="stepperSteps"
        :submit-button-text="getSubmitButtonText"
        @submit="onSubmit"
        v-model="stepperCurrentStep"
      />

      <AppButton
        v-if="!isEditing"
        :text="getSubmitButtonText"
        @click="onSubmit({ step: stepperCurrentStep })"
      />
    </template>

    <template v-slot:default>
      <keep-alive>
        <template v-if="stepperCurrentStep === 1">
          <Step1
            :roles="roles"
            :fleets="fleets"
            :user-id="theUserId"
            :currencies="currencies"
            :form-data="getStep1FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
            ref="step1"
          />
        </template>

        <template v-else-if="stepperCurrentStep === 2">
          <Step2
            :user-id="theUserId"
            :roles="roles"
            :form-data="getStep2FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
            ref="step2"
          />
        </template>

        <template v-else-if="stepperCurrentStep === 3">
          <Step3
            :countries="countries"
            :user-id="theUserId"
            :form-data="getStep3FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
            ref="step3"
          />
          <!-- todo: save dirty step info as well -->
        </template>
      </keep-alive>
    </template>
  </EdgeStack>
</template>

<script>
import roleApi from '@/config/api/role'
import { DropdownConfig } from '@/config/DropdownConfig'
import EdgeStack from '@/components/modals/EdgeStack'
import XStepper from '@/components/stepper/XStepper'
import XStepperNavigation from '@/components/stepper/XStepperNavigation'
import AppButton from '@/components/form/AppButton.vue'
import Step1 from './add-edit/Step1'
import Step2 from './add-edit/Step2'
import Step3 from './add-edit/Step3'
import { useEndpoints } from '@/composables'

export default {
  name: 'UserAddEdit',
  components: {
    EdgeStack,
    XStepper,
    XStepperNavigation,
    AppButton,
    Step1,
    Step2,
    Step3,
  },
  props: {
    esId: {
      type: String,
      default: 'user-add-edit',
    },
    stepperMode: {
      type: String,
      default: 'free',
    },
    stepperStep: {
      type: Number,
      default: 1,
    },
    userId: {
      type: null,
      required: false,
    },
    userData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      errors: [],

      stepperSteps: this.isEditing
        ? [
            {
              title: this.$t(
                'components.organizationUsersManagement.addEdit.tabs.profile'
              ),
              free: true,
            },
            {
              title: this.$t(
                'components.organizationUsersManagement.addEdit.tabs.permission'
              ),
              free: true,
            },
            {
              title: this.$t(
                'components.organizationUsersManagement.addEdit.tabs.settings'
              ),
              free: true,
            },
          ]
        : [
            {
              title: this.$t(
                'components.organizationUsersManagement.addEdit.tabs.profile'
              ),
              free: true,
            },
            {
              title: this.$t(
                'components.organizationUsersManagement.addEdit.tabs.permission'
              ),
              free: false,
            },
            {
              title: this.$t(
                'components.organizationUsersManagement.addEdit.tabs.settings'
              ),
              free: false,
            },
          ],

      stepperCurrentStep: null,

      roles: null,
      fleets: null,
      countries: null,
      currencies: null,

      theUserId: null,
    }
  },
  computed: {
    isEditing() {
      return !!this.userData
    },
    getTitleText() {
      return this.isEditing
        ? this.$t(
            'components.organizationUsersManagement.addEdit.headline.update'
          )
        : this.$t('components.organizationUsersManagement.addEdit.headline.add')
    },
    getSubmitButtonText() {
      if (this.stepperCurrentStep > this.stepperSteps.length - 1)
        return this.$t('components.stepNavigation.finish')

      return this.isEditing
        ? this.$t('components.stepNavigation.update')
        : this.$t('components.stepNavigation.saveAndContinue')
    },

    getStep1FormData() {
      if (this.userData) {
        const user = this.userData

        // serialize fleets data as plain array of ids
        let fleets = []
        if (user?.assigned_fleets?.length) {
          fleets = user.assigned_fleets.map((x) => {
            return x.id
          })
        }

        let selectedRoles = []
        if (user?.roles?.length) {
          selectedRoles = user.roles.map((x) => {
            return x.id
          })
        }

        return {
          profile_pic: user?.profile?.profile_pic,
          selectedRoles: [...selectedRoles],
          role_name: user?.role?.role_name,
          username: user?.username,
          ranger_job_type: user?.profile?.ranger_job_type,
          full_name: user.full_name,
          country_code: user.country_code,
          phone_number: user.phone_number,
          email: user.email,
          // password is actually undefined
          password: user.password,
          birthday: user?.profile.birthday,
          gender: user?.profile.gender,
          assigned_fleets: [...fleets],
          preferred_currency: user?.preferred_currency?.id,
        }
      }
      return null
    },

    getStep2FormData() {
      if (this.userData) {
        const user = this.userData

        let selectedRoles = []

        if (user?.roles?.length) {
          selectedRoles = user.roles.map((x) => {
            return x.id
          })
        }

        return {
          selectedRoles: [...selectedRoles],
        }
      }
      return null
    },
    getStep3FormData() {
      if (this.userData) {
        const paymentInfo = this.userData?.payment_info
          ? {
              account_name: this.userData?.payment_info?.account_name,
              account_number: this.userData?.payment_info?.account_number,
              branch_name: this.userData?.payment_info?.branch_name,
              country: this.userData?.payment_info?.country,
              routing_number: this.userData?.payment_info?.routing_number,
              is_banking_details_required:
                this.userData?.is_banking_details_required || false,
            }
          : null

        const documentInfo = this.userData?.document_info
          ? {
              nid_number: this.userData?.document_info?.nid_number,
              nid_front_side_pic: this.userData?.document_info
                ?.nid_front_side_pic,
              nid_back_side_pic: this.userData?.document_info
                ?.nid_back_side_pic,
              is_verified: this.userData?.document_info?.is_verified,
              has_driving_license: this.userData?.document_info
                ?.has_driving_license,
              is_document_required:
                this.userData?.is_document_required || false,
            }
          : null

        const addressInfo = this.userData?.address_info
          ? {
              // current address
              current_address_country: this.userData?.address_info
                .current_address_country,
              current_address_zip_code: this.userData?.address_info
                .current_address_zip_code,
              current_address_full: this.userData?.address_info
                .current_address_full,
              // permanent adress
              permanent_address_country: this.userData?.address_info
                .permanent_address_country,
              permanent_address_zip_code: this.userData?.address_info
                .permanent_address_zip_code,
              permanent_address_full: this.userData?.address_info
                .permanent_address_full,
              is_address_required: this.userData?.is_address_required || false,
            }
          : null

        return {
          paymentInfo: paymentInfo,
          documentInfo: documentInfo,
          addressInfo: addressInfo,
        }
      }
      return null
    },
  },
  async created() {
    // todo: loader
    this.roles = await this.$http
      .get(roleApi.index)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
    this.roles.push({
      id: this.$store.getters['auth/roleInfo'].id,
      role_name: 'Owner',
    })
    this.fleets = await this.$http
      .get(useEndpoints.dropdown.fleets())
      .then((res) => res.data.data)
      .catch((err) => console.log('err = ', err))
    this.countries = await this.$http
      .get(DropdownConfig.api.country)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
    this.currencies = await this.$http
      .get(DropdownConfig.api.organizationCurrencies)
      .then((res) => res.data)
      .catch((err) => console.log(err))
    // alert('user add edit data loaded')
  },
  mounted() {
    // reset current step to 1 on closed
    this.$edgeStack.emitter.on(
      this.$edgeStack.getEventName('closed', this.esId),
      () => {
        this.stepperCurrentStep = 1
      }
    )
  },
  beforeDestroy() {
    this.$edgeStack.emitter.off(
      this.$edgeStack.getEventName('closed', this.esId)
    )
  },
  watch: {
    userId: {
      immediate: true,
      handler(updatedId) {
        // getUserId -> updatedId could be null (in add mode)
        if (updatedId) {
          this.theUserId = updatedId
        }
      },
    },
    stepperStep: {
      immediate: true,
      handler(updatedStep) {
        this.stepperCurrentStep = updatedStep
      },
    },
  },
  methods: {
    onDirty(type, id = this.esId) {
      return type === true
        ? this.$edgeStack.shouldConfirm(id)
        : this.$edgeStack.shouldNotConfirm(id)
    },
    onSave(e = { step: null, data: {} }) {
      console.log(e)
      if (e.step === 1) {
        // update theUserId
        this.theUserId = e.data.id
        // free the first 2 steps
        this.$xStepper.defineSteps(this.esId, [
          { title: 'Profile', free: true },
          { title: 'Permission', free: true },
          { title: 'Settings', free: false },
        ])

        if (this.stepperMode === 'free') {
          this.$edgeStack.shouldNotConfirm(this.esId)
        } else {
          this.$edgeStack.shouldConfirm(this.esId)
        }

        this.$xStepper.navigate(this.esId).next()
      }
      if (e.step === 2) {
        // free the first 3 steps
        this.$xStepper.defineSteps(this.esId, [
          { title: 'Profile', free: true },
          { title: 'Permission', free: true },
          { title: 'Settings', free: true },
        ])

        if (this.stepperMode === 'free') {
          this.$edgeStack.shouldNotConfirm(this.esId)
        } else {
          this.$edgeStack.shouldConfirm(this.esId)
        }

        this.$xStepper.navigate(this.esId).next()
      }
      if (e.step === 3) {
        // free all steps
        this.$xStepper.defineSteps(this.esId, [
          { title: 'Profile', free: true },
          { title: 'Permission', free: true },
          { title: 'Settings', free: true },
        ])

        this.$edgeStack.shouldNotConfirm(this.esId)
        this.$edgeStack.close(this.esId)

        this.$edgeStack.emitter.on(
          this.$edgeStack.getEventName('closed', this.esId),
          () => {
            this.stepperCurrentStep = 1
          }
        )

        window.dispatchEvent(new Event(this.$config.orgUser.events.refresh))
      }
    },
    onSubmit(step) {
      this.$refs[`step${step.step}`].submit()
    },
  },
}
</script>

<style scoped>
.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
  margin-top: -30px;
  margin-bottom: 6px;
}
</style>
