<template>
  <tr :class="className ? className : 's-row'">
    <slot />
  </tr>
</template>

<script>
export default {
  name: 'STableRow',
  props: {
    className: {
      type: [String, Object],
      required: false,
      default: null,
    },
    textFallbackAlways: {
      type: Boolean,
      required: false,
      default: false,
    },
    textFallbackAlwaysAs: {
      type: String,
      required: false,
      default: '--',
    },
  },
  provide() {
    return {
      $textFallbackAlways: this.textFallbackAlways,
      $textFallbackAlwaysAs: this.textFallbackAlwaysAs,
    }
  },
}
</script>

<style lang="scss" scoped>
@import './$s-table.scss';
</style>

<style lang="scss" scoped>
.host-child-trip-row {
  border-left: 10px solid #2563eb !important;
}
.child-trip-row {
  border-left: 10px solid #9ca3af !important;
}
</style>
