<template>
  <dropdown-trigger id="user-dropdown">
    <template v-slot:default="{ triggerStyle }">
      <!-- start: user-dropdown-trigger -->
      <div
        class="relative flex items-center justify-center border-l cursor-pointer hover:bg-gray-300 h-55px"
        :style="triggerStyle"
      >
        <!-- start: mobile-trigger -->
        <div
          class="flex items-center justify-center md:hidden w-55px"
          :style="triggerStyle"
        >
          <svg
            class="w-8 h-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
        </div>
        <!-- end: mobile-trigger -->

        <!-- start: desktop-trigger -->
        <div
          class="hidden select-none w-132px md:flex md:items-center md:justify-center"
          :style="triggerStyle"
        >
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
          <div class="flex flex-col ml-2 justfy-centere">
            <span class="text-sm font-semibold text-center text-gray-800">{{
              $user.first_name
            }}</span>
            <span class="text-xs text-center text-gray-600">{{ role }}</span>
          </div>
        </div>
        <!-- end: desktop-trigger -->
      </div>
      <!-- end: user-dropdown-trigger -->
    </template>
  </dropdown-trigger>
</template>

<script>
import DropdownTrigger from '@/components/dropdown/DropdownTrigger'

export default {
  name: 'UserDropdown',
  components: {
    DropdownTrigger,
  },
  data() {
    return {
      open: true,
    }
  },
  computed: {
    role: function() {
      if (this.$user.is_superadmin) {
        return 'Super Admin'
      } else {
        let rolesName = this.$roles.map((item) => item.role_name).join(', ')
        return rolesName
      }
    },
  },
}
</script>

<style></style>
