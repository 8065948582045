<template>
  <label
    class="block w-full pb-1 font-semibold leading-normal text-gray-700 text-14px  "
    :class="{
      'text-gray-700': !!error,
      'text-red-600': !!error,
      'flex items-center': isShowInfoSymbol,
    }"
  >
    <slot>{{ text }}</slot>
    <img
      v-if="isShowInfoSymbol"
      src="@/assets/icon/info.svg"
      class="cursor-pointer ml-1 h-4 w-4"
      v-tooltip.top="`<div class='w-40 p-1 text-xs'>${info}</div>`"
    />
  </label>
</template>

<script>
export default {
  name: 'InputLabel',
  props: {
    text: {
      type: String,
    },
    error: {
      type: String,
    },
    isShowInfoSymbol: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
      default: '',
    },
  },
}
</script>

<style></style>
