<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form
      @submit.prevent="handleSubmit(submitStep)"
      enctype="multipart/form-data"
      class="sm:pb-24 2xl:px-6 2xl:pb-3 sm:px-1"
    >
      <AppInput
        type="richselect"
        name="Role Type"
           :label="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.permission.title.roleType'
                  )
                "
        rules="required"
        :options.sync="roles"
        valueAttribute="id"
        textAttribute="role_name"
        :placeholder="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.permission.placeHolder.selectRole'
                  )
                "
        :hide-search-box="true"
        v-model="form.selectedRoles"
        multiple
      />

      <div class="mt-1">
        <div class="grid grid-cols-1 " v-if="permissionsDetails.length > 0">
          <div class="mb-1">
            <p class=" font-semibold text-14px text-oDark">Permissions</p>
          </div>
          <div
            class="grid grid-cols-12 my-3"
            v-for="(item, index) in permissionsDetails"
            :key="index"
          >
            <div class="col-span-3 font-medium text-14px text-oDark">
              {{ item.name }}
            </div>

            <div class="col-span-9">
              <div class="flex gap-1 md:gap-2">
                <div
                  :key="childItemIndex"
                  v-for="(childItem, childItemIndex) in item.action_types"
                >
                  <p
                    class="border-2 px-1.5 lg:px-3 py-1 border-gray-400 rounded-full flex items-center justify-center text-xs md:text-sm"
                  >
                    {{ childItem }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="permissionsDetails.length === 0 && !isLoading"
          class="text-center"
        >
          <p>{{$t(
                    'components.organizationUsersManagement.addEdit.steps.permission.subtext.noPermissionsFound'
                  )}}</p>
        </div>
      </div>

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { deepCompareObjects } from '@/utils'
import { OrganizationUserConfig } from '@/config/OrganizationUserConfig'
import { useEndpoints } from '@/composables'

export default {
  name: 'Step2',
  components: {},
  props: {
    userId: {
      type: null,
      required: false,
    },
    roles: {
      required: true,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      form: {
        selectedRoles: [],
      },
      permissionsDetails: [],
    }
  },
  watch: {
    // sync props.formData with $data.from
    'formData': {
      immediate: true,
      deep: true,
      handler(data) {
        if (data) {
          this.form = { ...data }
        }
      },
    },
    // notify form is dirty & user should confirm before exiting
    'form': {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        this.$emit('dirty', { step: 1, data: this.form })
      },
    },
    'form.selectedRoles': {
      immediate: true,
      deep: true,
      handler(updatedRoles) {
        if (updatedRoles.length > 0) {
          this.isLoading = true
          this.permissionsDetails = []
          const roleReqs = []
          updatedRoles.forEach((id) => {
            const roleReq = this.$http.get(useEndpoints.role.details(id))
            roleReqs.push(roleReq)
          })
          this.$http
            .all(roleReqs)
            .then(
              this.$http.spread((...responses) => {
                responses.forEach((item) =>
                  this.permissionsDetails.push(item.data.permissions)
                )

                this.permissionsDetails = this.mergeArrays(
                  this.permissionsDetails
                )
              })
            )
            .finally(() => (this.isLoading = false))
        } else {
          this.permissionsDetails = []
        }
      },
    },
  },
  methods: {
    mergeArrays(arrays) {
      const merged = {}

      arrays.forEach((array) => {
        array.forEach((subArray) => {
          const { id, name, action_types } = subArray
          if (!merged[id]) {
            merged[id] = { id, name, action_types: [...action_types] }
          } else {
            merged[id].action_types = [
              ...new Set([...merged[id].action_types, ...action_types]),
            ]
          }
        })
      })

      return Object.values(merged)
    },
    async submit() {
      this.$refs.submitButton.click()
      console.log('submitButton')
    },

    async submitStep() {
      const formDataProxy = { ...this.form }

      const method = 'PATCH'
      const url = OrganizationUserConfig.api.update(this.userId)

      let data = new FormData()

      if (formDataProxy?.selectedRoles?.length) {
        formDataProxy.selectedRoles.forEach((item) => {
          data.append('role_id', item)
        })
      }

      this.isLoading = true
      await this.$http({ method, url, data })
        .then((res) => {
          this.$emit('save', { step: 2, data: res.data })

          const message = `Organization User ${
            this.isEditing ? 'updated' : 'added'
          } successfully`

          this.$notify(
            {
              group: 'important',
              type: 'success',
              title: 'Success',
              text: message,
            },
            5000
          )
        })
        .catch((err) => {
          console.log('errorOccurred', err.response)
          // todo: emit error
          this.$notify(
            {
              group: 'important',
              type: 'error',
              title: 'Error occurred!',
              text: err.response.data.message ?? err.response.data,
            },
            12000
          )
          console.warn(err)
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>
