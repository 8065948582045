<template>
  <div>
    <!-- DEPRECATED: USE BOTTOM-LEFT -->
    <!-- Default Notification Group: Bottom Right Position-->
    <notificationGroup group="generic">
      <div :class="bottomRight">
        <div class="w-full max-w-sm">
          <notification v-slot="{ notifications }">
            <div
              :class="wrapper"
              v-for="notification in notifications"
              :key="notification.id"
            >
              <div v-if="notification.type === 'success'">
                <toast-notification-success :notification="notification" />
              </div>
              <div v-else-if="notification.type === 'error'">
                <toast-notification-error :notification="notification" />
              </div>
              <div v-else>
                <toast-notification-default :notification="notification" />
              </div>
            </div>
          </notification>
        </div>
      </div>
    </notificationGroup>

    <!-- DEPRECATED: USE BOTTOM-RIGHT -->
    <!-- Important Notification Group: Bottom Left Position-->
    <notificationGroup group="important">
      <div :class="bottomLeft" class="z-big">
        <div class="w-full max-w-sm">
          <notification v-slot="{ notifications }">
            <div
              class="flex overflow-hidden mx-auto mt-4 w-full max-w-sm bg-white rounded-lg shadow-md"
              v-for="notification in notifications"
              :key="notification.id"
            >
              <div v-if="notification.type === 'success'">
                <toast-notification-success :notification="notification" />
              </div>
              <div v-else-if="notification.type === 'error'">
                <toast-notification-error :notification="notification" />
              </div>
              <div v-else>
                <toast-notification-default :notification="notification" />
              </div>
            </div>
          </notification>
        </div>
      </div>
    </notificationGroup>

    <notificationGroup group="bottomLeft">
      <div :class="bottomLeft" class="z-big">
        <div class="w-full max-w-sm">
          <notification v-slot="{ notifications }">
            <div
              class="flex overflow-hidden mx-auto mt-4 w-full max-w-sm bg-white rounded-lg shadow-md"
              v-for="notification in notifications"
              :key="notification.id"
            >
              <div v-if="notification.type === 'success'">
                <toast-notification-success :notification="notification" />
              </div>
              <div v-else-if="notification.type === 'error'">
                <toast-notification-error :notification="notification" />
              </div>
              <div v-else>
                <toast-notification-default :notification="notification" />
              </div>
            </div>
          </notification>
        </div>
      </div>
    </notificationGroup>

    <notificationGroup group="bottomRight">
      <div :class="bottomRight" class="z-big">
        <div class="w-full max-w-sm">
          <notification v-slot="{ notifications }">
            <div
              class="flex overflow-hidden mx-auto mt-4 w-full max-w-sm bg-white rounded-lg shadow-md"
              v-for="notification in notifications"
              :key="notification.id"
            >
              <div v-if="notification.type === 'success'">
                <toast-notification-success :notification="notification" />
              </div>
              <div v-else-if="notification.type === 'error'">
                <toast-notification-error :notification="notification" />
              </div>
              <div v-else>
                <toast-notification-default :notification="notification" />
              </div>
            </div>
          </notification>
        </div>
      </div>
    </notificationGroup>
  </div>
</template>

<script>
import ToastNotificationSuccess from './ToastNotificationSuccess'
import ToastNotificationError from './ToastNotificationError'
import ToastNotificationDefault from './ToastNotificationDefault'

export default {
  components: {
    ToastNotificationSuccess,
    ToastNotificationError,
    ToastNotificationDefault,
  },
  name: 'ToastNotification',
  data() {
    return {
      top:
        'fixed inset-0 z-50 flex items-start px-4 py-6 pt-12 pointer-events-none',
      bottom:
        'fixed inset-x-0 bottom-0 z-50 flex items-start px-4 py-6 pointer-events-none',
      wrapper:
        'flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-2xl',
    }
  },
  computed: {
    topLeft: function() {
      return this.top + ' justify-start pl-16'
    },
    topRight: function() {
      return this.top + ' justify-end'
    },
    bottomLeft: function() {
      return this.bottom + ' justify-start pl-16'
    },
    bottomRight: function() {
      return this.bottom + ' justify-end z-big'
    },
    positions: function() {
      return {
        topleft: this.topLeft,
        topRight: this.topRight,
        bottomLeft: this.bottomLeft,
        bottomRight: this.bottomRight,
      }
    },
  },
}
</script>

<style scoped>
.z-1200 {
  z-index: 1200;
}
.z-big {
  z-index: 9999 !important;
}
</style>
