<template>
  <div
    :class="getWrapperClasses"
    @click="onClick"
    @dragover="onDragover"
    @dragleave="onDragleave"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @drop="onDrop"
  >
    <input
      type="file"
      class="absolute w-0 h-0 overflow-hidden opacity-0"
      accept=".png, .jpg"
      @change="onChange"
      ref="file"
    />

    <div
      v-if="previewImage || current"
      key="upload-preview-or-placeholder"
      class="w-full h-full relative flex justify-center items-center"
    >
      <img
        v-if="current && !previewImage"
        :src="current"
        class="object-cover w-full h-full overflow-hidden"
      />
      <img
        v-if="previewImage"
        :src="previewImage"
        class="object-cover w-full h-full overflow-hidden"
      />
      <p
        v-if="getShowPreviewTextStatus"
        class="absolute text-oWhite text-base px-2 py-1 bg-gray-400 rounded-md "
      >
        Press to replace
      </p>
    </div>

    <div
      v-else
      key="upload-preview-or-placeholder"
      class="grid place-items-center"
    >
      <span class="upload-text">{{ text }}</span>
      <button type="button" class="upload-button">{{ buttonText }}</button>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
export default {
  name: 'UploadImageAlt',
  props: {
    value: {
      required: false,
    },
    current: {
      required: false,
    },
    text: {
      type: String,
      required: false,
      default: 'Select or Drag files to upload',
    },
    buttonText: {
      type: String,
      required: false,
      default: 'Upload',
    },
    variant: {
      requried: false,
      default: 'default',
    },
    disabled: {
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isHovering: false,
      file: null,
      previewImage: null,
    }
  },
  computed: {
    getWrapperClasses() {
      return 'upload-wrapper'
    },
    getShowPreviewTextStatus() {
      return this.isHovering
    },
  },
  mounted() {
    EventBus.$on('checkbox-status', (disabledStatus) => {
      this.disabled = disabledStatus
    })
  },
  methods: {
    onChange() {
      console.log('onChange-v')
      const input = this.$refs.file
      const file = input.files

      if (file && file[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },
    onClick() {
      if (!this.disabled) {
        this.$refs.file.click()
      }
    },
    onDragover(event) {
      console.log('dragover = ', event)
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('onDragover')) {
        event.currentTarget.classList.add('onDragover')
      }
    },
    onDragleave(event) {
      // Clean up
      console.log('dragleave = ', event)
      event.currentTarget.classList.remove('onDragover')
      event.currentTarget.classList.add('onDragleave')
    },
    onDrop(event) {
      console.log('drop = ', event)
      event.preventDefault()

      this.$refs.file.files = event.dataTransfer.files
      // Trigger the onChange event manually
      this.onChange()

      event.currentTarget.classList.remove('onDragover')
      event.currentTarget.classList.remove('onDragleave')
      event.currentTarget.classList.add('onDrop')
    },
    onMouseEnter() {
      this.isHovering = true
    },
    onMouseLeave() {
      this.isHovering = false
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-wrapper {
  background: #f3f3f3;
  min-height: 160px;
  overflow: hidden;
  @apply relative rounded border-dotted border-2 border-gray-300 items-center justify-center flex cursor-pointer;
  @apply hover:bg-gray-100 hover:border-blue-300;

  &.onDragover {
    @apply bg-green-50 border-green-200;
  }

  &.onDragleave,
  &.onDrop {
    background: #f3f3f3;
  }

  .upload-button {
    min-width: 115px;
    height: 35px;
    border-radius: 3px;
    background-color: #e7e7e9;
    font-size: 13px;
    font-weight: bold;
    color: #454545;

    @apply px-2 hover:shadow-sm hover:bg-gray-200 outline-none appearance-none focus:outline-none focus:ring-0;
  }

  .upload-text {
    font-size: 13px;
    color: #87888e;
    text-align: center;
    margin-bottom: 10px;
  }
}
</style>
