<template>
  <header
    class="sticky top-0 flex  justify-between w-full bg-white shadow-md h-55px"
    style="z-index: 200"
  >
    <div class="flex items-center">
      <div id="topbar-left" class="lg:px-4 lg:py-3 sm:p-0">
        <div
          class="flex items-center w-auto h-full ml-16 lg:ml-0"
          v-show="logoVisibility"
        >
          <template v-if="redirectStatus">
            <router-link :to="{ name: 'Home' }">
              <img :src="organizationLogo" class="object-cover " />
            </router-link>
          </template>
          <img v-else :src="organizationLogo" class="object-cover " />
        </div>
      </div>
    </div>
    <div class="hidden lg:block">
      <div class="centered-div ">
        <OtoSmartSearch
          v-model="currentSmartSearch"
          @input="debounceSearch"
          :isLoading="isSmartSearching"
          @focus="isOpen = true"
        />
      </div>
      <div
        v-if="isOpen"
        :id="searchDivId"
        class="result-centered-div bg-gray-50  rounded-lg  p-1 max-h-96 overflow-auto"
        style="width:50rem"
      >
        <LocalSearchResult
          v-if="getSearchResultFromLocal.length > 0"
          :localSearchResult="getSearchResultFromLocal"
          @display="displayLocalSearchText"
        />
        <ServerSearchResult
          v-if="getSearchResultFromServer.length > 0"
          :serverSearchResult="getSearchResultFromServer"
          :resultType="searchKey"
        />

        <p
          v-if="isSmartSearching"
          class="p-1 font-semibold bg-gray-100 text-gray-700"
        >
          Searching..
        </p>
        <p
          v-if="isNoResultFound && !isSmartSearching"
          class="p-1 font-semibold bg-gray-100 text-gray-700"
        >
          No Result Found
        </p>
      </div>
    </div>

    <div id="topbar-right" class="flex items-center">
      <HelpCenterDropdownTrigger />
      <WhatsNewDropdownTrigger />
      <notifications-dropdown-trigger />
      <user-dropdown-trigger />
    </div>
  </header>
</template>
<script>
import NotificationsDropdownTrigger from '@/components/layout/topbar/NotificationsDropdownTrigger'
import UserDropdownTrigger from '@/components/layout/topbar/UserDropdownTrigger'
import WhatsNewDropdownTrigger from '@/components/layout/topbar/WhatsNewDropdownTrigger.vue'
import HelpCenterDropdownTrigger from '@/components/layout/topbar/HelpCenterDropdownTrigger.vue'
import OtoSmartSearch from '@/components/ui/OtoSmartSearch.vue'
import { LocalData, ApiResource } from '@/resources/SmartSearchResource.js'
import debounce from 'lodash.debounce'
import LocalSearchResult from '@/components/layout/topbar/LocalSearchResult.vue'
import ServerSearchResult from '@/components/layout/topbar/ServerSearchResult.vue'
export default {
  name: 'AppTopbar',
  components: {
    NotificationsDropdownTrigger,
    UserDropdownTrigger,
    WhatsNewDropdownTrigger,
    HelpCenterDropdownTrigger,
    OtoSmartSearch,
    LocalSearchResult,
    ServerSearchResult,
  },
  props: {
    logoVisibility: {
      type: Boolean,
      required: false,
      default: false,
    },
    organizationLogo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      searchDivId: 'search-result-div',
      isSmartSearching: false,
      isNoResultFound: false,
      redirectStatus: false,
      currentSmartSearch: '',
      dataForLocalSearch: LocalData,
      dataFromServer: [],
      searchKey: '',
    }
  },
  computed: {
    getSearchResultFromLocal() {
      if (this.currentSmartSearch.length) {
        const result = this.dataForLocalSearch.filter((keyItem) => {
          return keyItem.type
            .toLowerCase()
            .includes(this.currentSmartSearch.toLowerCase())
        })
        return result
      }
      return this.dataForLocalSearch
    },
    getSearchResultFromServer() {
      return this.dataFromServer
    },
  },

  mounted() {
    try {
      this.redirectStatus = true
    } catch (err) {
      console.log('erros', err)
      this.redirectStatus = false
    }

    document.addEventListener('click', (event) => {
      if (!document.getElementById(this.searchDivId).contains(event.target)) {
        this.isOpen = false
      }
    })
  },
  methods: {
    displayLocalSearchText(e) {
      this.currentSmartSearch = e
    },
    getURL(searchKey, searchValue) {
      const omitQuestionMarkKeys = ['vehicle']
      let url = ''

      if (
        omitQuestionMarkKeys.includes(searchKey) &&
        ApiResource[searchKey] &&
        searchValue.length
      ) {
        url = `${
          ApiResource[this.searchKey.toLowerCase()]
        }&page=1&limit=5&offset=0&search=${searchValue}`
      } else {
        url = `${
          ApiResource[this.searchKey.toLowerCase()]
        }?page=1&limit=5&offset=0&search=${searchValue}`
      }
      return url
    },
    debounceSearch: debounce(function(searchStr) {
      if (searchStr.length && searchStr.includes(':')) {
        this.dataFromServer = []
        const searchKeyValue = searchStr.split(':')
        this.searchKey = searchKeyValue[0]
        const searchValue = searchKeyValue[1]

        let url = this.getURL(this.searchKey.toLowerCase(), searchValue)

        if (searchValue.length) {
          this.fetchResult(url)
        }
      } else {
        this.dataFromServer = []
      }
    }, 1000),
    async fetchResult(url) {
      this.isSmartSearching = true
      await this.$http
        .get(url)
        .then((res) => {
          this.dataFromServer = res.data.data
          if (this.dataFromServer.length > 0) {
            this.isNoResultFound = false
          } else {
            this.isNoResultFound = true
          }
        })
        .finally(() => (this.isSmartSearching = false))
    },
  },
}
</script>

<style>
#topbar-left img {
  height: 50px;
}
.w-55px {
  width: 55px;
}
.w-132px {
  width: 132px;
}
.h-55px {
  height: 55px;
}
.bg-red-notification {
  background-color: #d90a20;
}

.text-red-notification {
  color: #d90a20;
}
.centered-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.result-centered-div {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 2px 2px 10px 5px rgba(128, 128, 128, 0.5);
}
</style>
