<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form
      @submit.prevent="handleSubmit(submitStep)"
      enctype="multipart/form-data"
      class="pt-6 sm:pb-24 2xl:px-6 2xl:pb-3 sm:px-10"
    >
      <!-- Payment Details - Start -->
      <div>
        <h1 class="pb-2">{{$t(
                    'components.organizationUsersManagement.addEdit.steps.settings.paymentDetails.subHeading'
                  )}}</h1>
        <label class="flex items-center w-full mt-4 mb-4 cursor-pointer">
          <TCheckbox v-model="is_not_banking_details_required" />

          <span
            class="ml-3 font-semibold select-none text-14p mt-px text-oBlack"
          >
            {{$t(
                    'components.organizationUsersManagement.addEdit.steps.settings.paymentDetails.checkBox.iDontHavePaymentDetails'
                  )}}
          </span>
        </label>
      </div>

      <div class="grid grid-cols-2 gap-4 mt-8">
        <AppInput
          :rules="
            !form.paymentInfo.is_banking_details_required ? '' : 'required'
          "
          :disabled="!form.paymentInfo.is_banking_details_required"
           :label="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.paymentDetails.title.bankAccountName'
                  )
                "
          placeholder="e.g Junior Martin"
          v-model="form.paymentInfo.account_name"
        />

        <AppInput
          :rules="
            !form.paymentInfo.is_banking_details_required ? '' : 'required'
          "
          :disabled="!form.paymentInfo.is_banking_details_required"
          type="number"
           :label="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.paymentDetails.title.bankAccountNo'
                  )
                "
          placeholder="e.g 110995330"
          v-model="form.paymentInfo.account_number"
        />
      </div>

      <div class="grid grid-cols-2 gap-4">
        <AppInput
          placeholder="e.g. NY City Bank, NY"
          :rules="
            !form.paymentInfo.is_banking_details_required ? '' : 'required'
          "
          :disabled="!form.paymentInfo.is_banking_details_required"
           :label="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.paymentDetails.title.branch'
                  )
                "
          v-model="form.paymentInfo.branch_name"
        />

        <AppInput
          name="Country"
          :label="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.paymentDetails.title.country'
                  )
                "
          :rules="
            !form.paymentInfo.is_banking_details_required ? '' : 'required'
          "
          :disabled="!form.paymentInfo.is_banking_details_required"
          :options.sync="countries"
          valueAttribute="id"
          textAttribute="name"
          :placeholder="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.paymentDetails.placeHolder.country'
                  )
                "
          v-model="form.paymentInfo.country"
          type="richselect"
        />
      </div>

      <AppInput
        placeholder="e.g. 026013673"
        :rules="!form.paymentInfo.is_banking_details_required ? '' : 'required'"
        :disabled="!form.paymentInfo.is_banking_details_required"
         :label="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.paymentDetails.title.routingNumber'
                  )
                "
        type="number"
        v-model="form.paymentInfo.routing_number"
      />
      <!-- Payment Details - End -->

      <!-- Document - Start -->
      <div>
        <h1 class="pb-2">{{$t(
                    'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.subHeading'
                  )}}</h1>
        <label class="flex items-center w-full mt-4 mb-4 cursor-pointer">
          <TCheckbox v-model="is_not_document_required" />

          <span
            class="ml-3 font-semibold select-none text-14p mt-px text-oBlack"
          >
            {{$t(
                    'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.checkBox.iWantToSkipThisStep'
                  )}}
          </span>
        </label>
      </div>
      <div class="flex items-center pt-8">
        <AppInput
          :rules="!form.documentInfo.is_document_required ? '' : 'required'"
          :disabled="!form.documentInfo.is_document_required"
         :label="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.title.nidNumber'
                  )
                "
          placeholder="e.g. 92344563432"
          v-model="form.documentInfo.nid_number"
        />
      </div>

      <div class="grid grid-cols-2 gap-4 mt-8 ">
        <div>
          <ValidationProvider
            tag="div"
            class="mt-2"
            :name="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.title.nidFrontSide'
                  )
                "
            :rules="!form.documentInfo.is_document_required ? '' : 'required'"
            v-slot="{ errors }"
          >
            <input-label
              :error="errors[0]"
              :text="
                `NID Front Side ${
                  !form.documentInfo.is_document_required ? '' : '*'
                }`
              "
            />

            <UploadImageAlt
              class="max-h-14"
              :current="form.documentInfo.nid_front_side_pic"
              :text="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.uploadImage1.text'
                  )
                "
                 :button-text="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.uploadImage1.buttonText'
                  )
                "
             
              v-model="form.documentInfo.nid_front_side_pic"
            />

            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>

        <div>
          <ValidationProvider
            tag="div"
            class="mt-2"
         :name="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.title.nidFrontSide'
                  )
                "
            :rules="!form.documentInfo.is_document_required ? '' : 'required'"
            v-slot="{ errors }"
          >
            <input-label
              :error="errors[0]"
              :text="
                `NID Back Side ${
                  !form.documentInfo.is_document_required ? '' : '*'
                }`
              "
            />

            <UploadImageAlt
              class="max-h-14"
              :current="form.documentInfo.nid_back_side_pic"
                    :text="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.uploadImage2.text'
                  )
                "
                 :button-text="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.uploadImage2.buttonText'
                  )
                "
              v-model="form.documentInfo.nid_back_side_pic"
            />

            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>
      </div>
      <div>
        <label class="flex items-center w-full mt-4 mb-4 cursor-pointer">
          <TCheckbox
            v-model="form.documentInfo.is_verified"
            :disabled="is_not_document_required"
          />

          <span
            :class="[
              'ml-3 font-semibold select-none text-14p mt-px',
              is_not_document_required ? 'text-gray-500' : 'text-oBlack',
            ]"
          >
            {{$t(
                    'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.checkBox.verified'
                  )}}
          </span>
        </label>
      </div>

      <h1 class="pt-12">{{$t(
                    'components.organizationUsersManagement.addEdit.steps.settings.drivingLicense.subHeading'
                  )}}</h1>
      <div class="mt-6">
        <!-- <input-label :error="``"  /> -->
        <AppInput
          name="Driving License"
               :label="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.drivingLicense.title.drivingLicense'
                  )
                "
          :options="getLicenseOptions"
          :text-attribute="`text`"
          :value-attribute="`value`"
          :hide-search-box="true"
          :disabled="!form.documentInfo.is_document_required"
          v-model="form.documentInfo.has_driving_license"
        />
      </div>

      <!-- disabled for now -->
      <div class="grid grid-cols-2 gap-4 mt-8" v-if="false">
        <div>
          <input-label :error="``"   :text="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.drivingLicense.title.uploadImage1.text'
                  )
                " />
          <upload-image
            type="w-full"
            :idx="0"
            :current="form.documentInfo.nid_front_side_pic"
            @input="notifyDirty(true)"
           :title="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.drivingLicense.title.uploadImage1.title'
                  )
                "
            :disabled="is_not_document_required"
          />
        </div>

        <div>
          <input-label :error="``" :text="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.drivingLicense.title.uploadImage2.text'
                  )
                " />
          <upload-image
            type="w-full"
            :idx="1"
            :current="form.documentInfo.nid_back_side_pic"
            @input="notifyDirty(true)"
           :title="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.drivingLicense.title.uploadImage2.title'
                  )
                "
            :disabled="is_not_document_required"
          />
        </div>
      </div>
      <!-- Document - End -->
      <!-- Address - Start -->
      <div>
        <h1 class="pb-2">{{$t(
                    'components.organizationUsersManagement.addEdit.steps.settings.currentAddress.subHeading'
                  )}}</h1>
        <label class="flex items-center w-full mt-4 mb-4 cursor-pointer">
          <TCheckbox v-model="is_not_address_required" />

          <span
            class="ml-3 font-semibold select-none text-14p mt-px text-oBlack"
          >
           {{$t(
                    'components.organizationUsersManagement.addEdit.steps.settings.currentAddress.checkBox.iWantToSkipThisStep'
                  )}}
          </span>
        </label>
      </div>

      <div class="grid grid-cols-2 gap-4 mt-8">
        <AppInput
          name="Country"
         :label="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.currentAddress.title.country'
                  )
                "
          :rules="!form.addressInfo.is_address_required ? '' : 'required'"
          :disabled="!form.addressInfo.is_address_required"
          :options.sync="countries"
          valueAttribute="id"
          textAttribute="name"
        :placeholder="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.currentAddress.placeHolder.country'
                  )
                "
          v-model="form.addressInfo.current_address_country"
          type="richselect"
        />

        <AppInput
          :rules="!form.addressInfo.is_address_required ? '' : 'required'"
          :disabled="!form.addressInfo.is_address_required"
            :label="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.currentAddress.title.zipCode'
                  )
                "
          placeholder="e.g. 53432"
          v-model="form.addressInfo.current_address_zip_code"
        />
      </div>

      <AppInput
        name="Address"
        :label="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.currentAddress.title.address'
                  )
                "
        type="textarea"
        v-model="form.addressInfo.current_address_full"
        placeholder="2307 Oak Street, Old Forge, New York."
        :disabled="!form.addressInfo.is_address_required"
      />

      <h1 class="pt-6">{{$t(
                    'components.organizationUsersManagement.addEdit.steps.settings.permanentAddress.subHeading'
                  )}}</h1>

      <label class="flex items-center w-full mt-8 mb-4 cursor-pointer">
        <TCheckbox
          v-model="permanentAddressIsSame"
          :disabled="!form.addressInfo.is_address_required"
        />

        <span
          :class="[
            'ml-3 font-semibold select-none text-14p mt-px',
            !form.addressInfo.is_address_required
              ? 'text-gray-500'
              : 'text-oBlack',
          ]"
        >
         {{$t(
                    'components.organizationUsersManagement.addEdit.steps.settings.permanentAddress.checkBox.sameAsCurrentAddress'
                  )}}
        </span>
      </label>

      <div class="grid grid-cols-2 gap-4 mt-8">
        <AppInput
          name="Country"
                :label="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.permanentAddress.title.country'
                  )
                "
          :rules="
            `${
              !form.addressInfo.is_address_required || permanentAddressIsSame
                ? ''
                : 'required'
            }`
          "
          :options.sync="countries"
          valueAttribute="id"
          textAttribute="name"
          :placeholder="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.permanentAddress.placeHolder.country'
                  )
                "
          type="richselect"
          v-model="form.addressInfo.permanent_address_country"
          :disabled="
            !form.addressInfo.is_address_required || permanentAddressIsSame
          "
          :variant="{
            readonly: permanentAddressIsSame,
          }"
        />

        <AppInput
          :rules="
            `${
              !form.addressInfo.is_address_required || permanentAddressIsSame
                ? ''
                : 'required'
            }`
          "
         :label="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.permanentAddress.title.zipCode'
                  )
                "
          placeholder="e.g 53432"
          :disabled="
            !form.addressInfo.is_address_required || permanentAddressIsSame
          "
          v-model="form.addressInfo.permanent_address_zip_code"
        />
      </div>

      <div>
        <AppInput
          name="Address"
           :label="
                  $t(
                    'components.organizationUsersManagement.addEdit.steps.settings.permanentAddress.title.address'
                  )
                "
          :rules="
            `${
              !form.addressInfo.is_address_required || permanentAddressIsSame
                ? ''
                : 'required'
            }`
          "
          placeholder="2307 Oak Street, Old Forge, New York."
          v-model="form.addressInfo.permanent_address_full"
          :disabled="
            !form.addressInfo.is_address_required || permanentAddressIsSame
          "
          :variant="{ readonly: permanentAddressIsSame }"
        />
      </div>

      <!-- Address - End -->
      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { xMan, deepCompareObjects, EventBus } from '@/utils'
import { OrganizationUserConfig } from '@/config/OrganizationUserConfig'
import InputLabel from '@/components/form/InputLabel'
import UploadImageAlt from '@/components/form/UploadImageAlt'

export default {
  name: 'Step3',
  components: {
    InputLabel,
    UploadImageAlt,
  },
  props: {
    countries: {
      required: true,
    },
    userId: {
      required: true,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      is_not_banking_details_required: true,
      is_not_document_required: true,
      is_not_address_required: true,
      permanentAddressIsSame: false,
      form: {
        paymentInfo: {
          account_name: '',
          account_number: '',
          branch_name: '',
          country: '',
          routing_number: '',
          is_banking_details_required: false,
        },
        documentInfo: {
          nid_number: '',
          nid_front_side_pic: '',
          nid_back_side_pic: '',
          is_verified: false,
          has_driving_license: false,
          is_document_required: false,
        },
        addressInfo: {
          // current address
          current_address_country: null,
          current_address_zip_code: '',
          current_address_full: '',
          // permanent address
          permanent_address_country: null,
          permanent_address_zip_code: '',
          permanent_address_full: '',
          is_address_required: false,
        },
      },
    }
  },
  watch: {
    // sync props.formData with $data.from
    formData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data) {
          const { paymentInfo } = data
          this.form.paymentInfo = { ...paymentInfo }
          this.is_not_banking_details_required = !this.form.paymentInfo
            .is_banking_details_required
        }
      },
    },
    // notify form is dirty & user should confirm before exiting
    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        // don't notify if editing data & user input data are same
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        // otherwise notify whenever there's new input / change
        this.$emit('dirty', { step: 2, data: this.form })
      },
    },
    is_not_banking_details_required: {
      immediate: true,
      deep: true,
      handler(data) {
        this.form.paymentInfo.is_banking_details_required = !data
      },
    },
    is_not_document_required: {
      immediate: true,
      deep: true,
      handler(data) {
        this.form.documentInfo.is_document_required = !data
        EventBus.$emit('checkbox-status', this.is_not_document_required)
      },
    },
    is_not_address_required: {
      immediate: true,
      deep: true,
      handler(data) {
        this.form.addressInfo.is_address_required = !data
      },
    },
    permanentAddressIsSame(updatedState) {
      if (updatedState) {
        this.form.addressInfo.permanent_address_country = this.form.addressInfo.current_address_country
        this.form.addressInfo.permanent_address_zip_code = this.form.addressInfo.current_address_zip_code
        this.form.addressInfo.permanent_address_full = this.form.addressInfo.current_address_full
      } else {
        this.form.addressInfo.permanent_address_country = ''
        this.form.addressInfo.permanent_address_zip_code = ''
        this.form.addressInfo.permanent_address_full = ''
      }
    },
  },
  methods: {
    async submit() {
      this.$refs.submitButton.click()
    },
    async submitStep() {
      this.isLoading = true
      const allReqs = []
      //payment req
      let paymentInfoURL = OrganizationUserConfig.api.payment_create(
        this.userId
      )
      let paymentInfoData = new xMan(this.form.paymentInfo).toFormData()
      let paymentInfoMethod = 'POST'
      const paymentInfoReq = this.$http({
        url: paymentInfoURL,
        method: paymentInfoMethod,
        data: paymentInfoData,
      })
      allReqs.push(paymentInfoReq)

      //document req
      let documentInfoURL = OrganizationUserConfig.api.document_create(
        this.userId
      )
      const documentFromDataProxy = { ...this.form.documentInfo }

      if (
        documentFromDataProxy.nid_front_side_pic == null ||
        typeof documentFromDataProxy.nid_front_side_pic === 'string'
      ) {
        delete documentFromDataProxy.nid_front_side_pic
      }

      if (
        documentFromDataProxy.nid_back_side_pic == null ||
        typeof documentFromDataProxy.nid_back_side_pic === 'string'
      ) {
        delete documentFromDataProxy.nid_back_side_pic
      }
      let documentInfoData = new xMan(documentFromDataProxy).toFormData()

      let documentInfoMethod = 'POST'
      const documentInfoReq = this.$http({
        url: documentInfoURL,
        method: documentInfoMethod,
        data: documentInfoData,
      })
      allReqs.push(documentInfoReq)
      console.log('documentInfoReq', documentInfoReq)

      //address req
      let addressInfoURL = OrganizationUserConfig.api.address_create(
        this.userId
      )
      let addressInfoData = new xMan(this.form.addressInfo).toFormData()
      let addressInfoMethod = 'PATCH'
      const addressInfoReq = this.$http({
        url: addressInfoURL,
        method: addressInfoMethod,
        data: addressInfoData,
      })

      allReqs.push(addressInfoReq)
      console.log('addressInfoReq', addressInfoReq)

      await this.$http
        .all(allReqs)
        .then(
          this.$http.spread((...responses) => {
            this.$notify(
              {
                group: 'important',
                type: 'success',
                title: 'Success',
                text: 'Update Successfully',
              },
              5000
            )
            this.$emit('save', { step: 3, data: {} })
            console.log('responses', responses)
          })
        )
        .catch((err) => {
          console.log('err-', { err })
          // todo: emit error
          var errorData = Object.values(err.message)
          if (errorData.length > 0) {
            this.$emit('show_error', errorData[0][0])
          }

          this.$notify(
            {
              group: 'important',
              type: 'error',
              title: 'Error occurred!',
              text: err.response.data.message ?? err.response.data,
            },
            12000
          )
          console.warn(err)
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 22px;
  font-weight: bold;
  color: #2e2e39;
}
</style>
