var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('form',{staticClass:" 2xl:px-6 sm:px-1",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitStep)}}},[_c('div',{staticClass:"grid items-center grid-cols-1  my-4 px-5"},[_c('div',{staticClass:"flex items-center w-full gap-4"},[_c('upload-avatar',{attrs:{"current":_vm.form.profile_pic},model:{value:(_vm.form.profile_pic),callback:function ($$v) {_vm.$set(_vm.form, "profile_pic", $$v)},expression:"form.profile_pic"}}),_c('AppInput',{attrs:{"rules":"required","label":_vm.$t(
              'components.organizationUsersManagement.addEdit.steps.profile.title.fullName'
            ),"placeholder":"e.g. Junior Martin"},model:{value:(_vm.form.full_name),callback:function ($$v) {_vm.$set(_vm.form, "full_name", $$v)},expression:"form.full_name"}})],1)]),_c('AppInput',{staticClass:"px-5",attrs:{"rules":"required|min:4","name":_vm.$t(
          'components.organizationUsersManagement.addEdit.steps.profile.title.userName'
        ),"label":_vm.$t(
          'components.organizationUsersManagement.addEdit.steps.profile.title.userName'
        ),"placeholder":_vm.$t(
          'components.organizationUsersManagement.addEdit.steps.profile.placeHolder.userName'
        ),"disabled":_vm.getDisableStatus},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('AppInput',{staticClass:"px-5",attrs:{"rules":"required","name":_vm.$t(
          'components.organizationUsersManagement.addEdit.steps.profile.title.phoneNumber'
        ),"label":_vm.$t(
          'components.organizationUsersManagement.addEdit.steps.profile.title.phoneNumber'
        ),"placeholder":"e.g 01700000000","type":"tel","haveSuggestion":true,"isSuggestionAlert":_vm.isPhoneNumberExist,"suggestionNonAlertClass":_vm.isPhoneNumberChecking
          ? "text-gray-700 font-semibold"
          : "text-green-500 font-semibold","suggestionText":_vm.suggestionsForPhoneNumber},on:{"input":_vm.onChangePhoneNumber,"country-changed":_vm.onCountryChanged},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}}),_c('AppInput',{staticClass:"px-5",attrs:{"placeholder":"e.g. martin@gmail.com","rules":"required","name":_vm.$t(
          'components.organizationUsersManagement.addEdit.steps.profile.title.email'
        ),"label":_vm.$t(
          'components.organizationUsersManagement.addEdit.steps.profile.title.email'
        ),"type":"email","haveSuggestion":true,"isSuggestionAlert":_vm.isEmailExist,"suggestionNonAlertClass":_vm.isEmailChecking
          ? "text-gray-700 font-semibold"
          : "text-green-500 font-semibold","suggestionText":_vm.suggestionsForEmail},on:{"input":_vm.onChangeEmail},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(!_vm.isEditing)?_c('AppInput',{staticClass:"px-5",attrs:{"type":'password',"name":_vm.$t(
          'components.organizationUsersManagement.addEdit.steps.profile.title.password'
        ),"label":_vm.$t(
          'components.organizationUsersManagement.addEdit.steps.profile.title.password'
        ),"rules":("" + (_vm.isEditing ? '' : 'required|min:6')),"disabled":_vm.isEditing},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}):_vm._e(),_c('AppInput',{staticClass:"px-5",attrs:{"rules":"required","type":"richselect","name":_vm.$t(
          'components.organizationUsersManagement.addEdit.steps.profile.title.assignedFleets'
        ),"label":_vm.$t(
          'components.organizationUsersManagement.addEdit.steps.profile.title.assignedFleets'
        ),"options":_vm.fleets,"text-attribute":"name","value-attribute":"id","hide-search-box":false,"placeholder":_vm.$t(
          'components.organizationUsersManagement.addEdit.steps.profile.placeHolder.assignedFleets'
        ),"multiple":""},model:{value:(_vm.form.assigned_fleets),callback:function ($$v) {_vm.$set(_vm.form, "assigned_fleets", $$v)},expression:"form.assigned_fleets"}}),_c('AppInput',{staticClass:"px-5",attrs:{"rules":"required","type":"richselect","name":_vm.$t(
          'components.organizationUsersManagement.addEdit.steps.profile.title.preferredCurrency'
        ),"label":_vm.$t(
          'components.organizationUsersManagement.addEdit.steps.profile.title.preferredCurrency'
        ),"options":_vm.currencies,"text-attribute":"name","value-attribute":"id","hide-search-box":false,"placeholder":_vm.$t(
          'components.organizationUsersManagement.addEdit.steps.profile.placeHolder.preferredCurrency'
        )},model:{value:(_vm.form.preferred_currency),callback:function ($$v) {_vm.$set(_vm.form, "preferred_currency", $$v)},expression:"form.preferred_currency"}}),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-2 px-5"},[_c('AppInput',{attrs:{"type":"richselect","label":_vm.$t(
            'components.organizationUsersManagement.addEdit.steps.profile.title.gender'
          ),"options":_vm.getGenders,"text-attribute":"text","value-attribute":"value","hide-search-box":true,"placeholder":_vm.$t(
            'components.organizationUsersManagement.addEdit.steps.profile.placeHolder.gender'
          )},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}}),_c('AppInput',{attrs:{"type":"date","placeholder":_vm.$t(
            'components.organizationUsersManagement.addEdit.steps.profile.placeHolder.dateOfBirth'
          ),"label":_vm.$t(
            'components.organizationUsersManagement.addEdit.steps.profile.title.dateOfBirth'
          ),"max":_vm.maxBirthDate},model:{value:(_vm.form.birthday),callback:function ($$v) {_vm.$set(_vm.form, "birthday", $$v)},expression:"form.birthday"}})],1),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }