var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('notificationGroup',{attrs:{"group":"generic"}},[_c('div',{class:_vm.bottomRight},[_c('div',{staticClass:"w-full max-w-sm"},[_c('notification',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var notifications = ref.notifications;
return _vm._l((notifications),function(notification){return _c('div',{key:notification.id,class:_vm.wrapper},[(notification.type === 'success')?_c('div',[_c('toast-notification-success',{attrs:{"notification":notification}})],1):(notification.type === 'error')?_c('div',[_c('toast-notification-error',{attrs:{"notification":notification}})],1):_c('div',[_c('toast-notification-default',{attrs:{"notification":notification}})],1)])})}}])})],1)])]),_c('notificationGroup',{attrs:{"group":"important"}},[_c('div',{staticClass:"z-big",class:_vm.bottomLeft},[_c('div',{staticClass:"w-full max-w-sm"},[_c('notification',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var notifications = ref.notifications;
return _vm._l((notifications),function(notification){return _c('div',{key:notification.id,staticClass:"flex overflow-hidden mx-auto mt-4 w-full max-w-sm bg-white rounded-lg shadow-md"},[(notification.type === 'success')?_c('div',[_c('toast-notification-success',{attrs:{"notification":notification}})],1):(notification.type === 'error')?_c('div',[_c('toast-notification-error',{attrs:{"notification":notification}})],1):_c('div',[_c('toast-notification-default',{attrs:{"notification":notification}})],1)])})}}])})],1)])]),_c('notificationGroup',{attrs:{"group":"bottomLeft"}},[_c('div',{staticClass:"z-big",class:_vm.bottomLeft},[_c('div',{staticClass:"w-full max-w-sm"},[_c('notification',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var notifications = ref.notifications;
return _vm._l((notifications),function(notification){return _c('div',{key:notification.id,staticClass:"flex overflow-hidden mx-auto mt-4 w-full max-w-sm bg-white rounded-lg shadow-md"},[(notification.type === 'success')?_c('div',[_c('toast-notification-success',{attrs:{"notification":notification}})],1):(notification.type === 'error')?_c('div',[_c('toast-notification-error',{attrs:{"notification":notification}})],1):_c('div',[_c('toast-notification-default',{attrs:{"notification":notification}})],1)])})}}])})],1)])]),_c('notificationGroup',{attrs:{"group":"bottomRight"}},[_c('div',{staticClass:"z-big",class:_vm.bottomRight},[_c('div',{staticClass:"w-full max-w-sm"},[_c('notification',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var notifications = ref.notifications;
return _vm._l((notifications),function(notification){return _c('div',{key:notification.id,staticClass:"flex overflow-hidden mx-auto mt-4 w-full max-w-sm bg-white rounded-lg shadow-md"},[(notification.type === 'success')?_c('div',[_c('toast-notification-success',{attrs:{"notification":notification}})],1):(notification.type === 'error')?_c('div',[_c('toast-notification-error',{attrs:{"notification":notification}})],1):_c('div',[_c('toast-notification-default',{attrs:{"notification":notification}})],1)])})}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }