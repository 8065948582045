<template>
  <div class="">
    <div
      v-if="showHeader && filteredNotificationItems.length"
      class="bg-gray-200 text-gray-500 px-5 py-2 font-bold"
    >
      {{ toDisplayDate(date) }}
    </div>
    <div class="">
      <template v-for="(notification, index) in filteredNotificationItems">
        <NotificationItem
          :notification="notification"
          :count="notification['stack_count']"
          :key="'notification' + index"
        />
      </template>
    </div>
  </div>
</template>
<script>
import helper from './helper'
import NotificationItem from './NotificationItem'
export default {
  components: {
    NotificationItem,
  },
  props: {
    categoryFilter: String,
    date: String,
    notifications: Array,
    notificationListLookUp: Object,
    groupedNotificationLookUp: Object,
    showHeader: Boolean,
  },
  methods: {
    toDisplayDate: helper.toDisplayDate,
  },
  watch: {
    filteredNotificationItems: {
      handler(filteredNotificationItems) {
        this.$emit('children-count-change', filteredNotificationItems.length)
      },
      immediate: true,
    },
  },
  computed: {
    filteredNotificationItems() {
      let filteredNotificationItems = []
      for (let index = 0; index < this.notifications.length; index++) {
        const notificationGroupKey = this.notifications[index]
        const notificationId = this.groupedNotificationLookUp[
          notificationGroupKey
        ][0]
        const notification =
          typeof this.notificationListLookUp[notificationId] !== 'undefined'
            ? this.notificationListLookUp[notificationId]
            : null
        const hasPassedCategoryFilter =
          this.categoryFilter === '' ||
          notification['category'] === this.categoryFilter
        if (notification && hasPassedCategoryFilter) {
          notification['group_count'] = this.groupedNotificationLookUp[
            notificationGroupKey
          ].length
          filteredNotificationItems.push(notification)
        } else if (notification === null) {
          console.error('Notification not found', notificationId)
        }
      }
      return filteredNotificationItems
    },
  },
}
</script>
