const monthName = [
  'January',
  'Febuary',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
function pad(n, width, z) {
  z = z || '0'
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

export default {
  toDisplayDate: (dateText, useDateOnly = false, displayTime = false) => {
    const date = new Date(dateText)
    const currentDate = new Date()
    if (
      !useDateOnly &&
      date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear() ===
        currentDate.getMonth() +
          1 +
          '/' +
          currentDate.getDate() +
          '/' +
          currentDate.getFullYear()
    ) {
      return 'Today'
    } else {
      let time = ''
      if (displayTime) {
        // convert to am pm
        let H = date.getHours()
        let h = H % 12 || 12
        let ampm = H < 12 ? ' AM' : ' PM'
        time = h + ':' + pad(date.getMinutes(), 2) + ampm
      }
      return (
        pad(date.getDate(), 2) +
        ' ' +
        monthName[date.getMonth()] +
        ' ' +
        date.getFullYear() +
        ' ' +
        time
      )
    }
  },
  generateExtraDataKey: (extraData) => {
    const fields = ['bike_id', 'power_level']
    let key = ''
    fields.forEach((field) => {
      key +=
        typeof extraData[field] !== 'undefined' ? '_' + extraData[field] : ''
    })
    return key
  },
  checkInView(container, element, partial = false) {
    //Get container properties
    let cTop = container.scrollTop
    let cBottom = cTop + container.clientHeight
    //Get element properties
    let eTop = element.offsetTop - 140
    let eBottom = eTop + element.clientHeight
    // console.log(container.scrollTop, container.clientHeight)
    //Check if in view
    let isTotal = eTop >= cTop && eBottom <= cBottom
    const isPartial = partial && eTop < cTop && eBottom > cTop
    //Return outcome
    return isTotal || isPartial
  },
}
