var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"helpCenterContainer"},[_c('t-dropdown',{attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
      var mousedownHandler = ref.mousedownHandler;
      var focusHandler = ref.focusHandler;
      var blurHandler = ref.blurHandler;
      var keydownHandler = ref.keydownHandler;
return [_c('div',{staticClass:"flex justify-center items-center px-4 border-l cursor-pointer hover:bg-yellow-200 w-55px h-55px ",attrs:{"aria-label":"Help Center Menu","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('div',{staticClass:"relative "},[_c('i',{staticClass:"fa-sharp fas fa-question text-gray-700 "})])])]}},{key:"default",fn:function(ref){
      var hide = ref.hide;
return [_c('div',{ref:"dropdown",staticClass:"py-1 mt-px bg-white rounded-md shadow-md font-normal",on:{"click":function($event){return hide()}}},[_c('t-dropdown-item',[_c('router-link',{attrs:{"to":"/help/getting-started","target":"_blank"}},[_c('div',{staticClass:"flex  justify-between"},[_c('p',{staticClass:"font-normal"},[_vm._v("Getting Started")]),_c('div',[_c('i',{staticClass:"fas fa-arrow-up-right-from-square text-blue-600 text-sm cursor-pointer"})])])])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }