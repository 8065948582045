export function generateRentDataModel(choices) {
  const generatedData = choices.map((choice) => {
    return {
      text: choiceMappingForPackages[choice.value]
        ? choiceMappingForPackages[choice.value]
        : choice.text,
      value: choice.value,
      data: [],
    }
  })

  return generatedData
}
export const choiceMappingForPackages = {
  H: 'Hourly Rent',
  D: 'Daily Rent',
}
