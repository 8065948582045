<template>
  <div>
    <ul class="">
      <li
        class="outer"
        v-for="(searchItem, searchItemIndex) in localSearchResult"
        :key="`local-search-${searchItemIndex}`"
        @click="takeAction(searchItem.type)"
      >
        <section class="inner">
          <div class="">
            <p>
              <span class="inner-type">{{ searchItem.type }}</span
              ><span>{{ searchItem.subText }}</span>
            </p>
          </div>
          <div>
            <p>{{ searchItem.example }}</p>
          </div>
        </section>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'LocalSearchResult',
  components: {},
  props: {
    localSearchResult: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  mounted() {},
  methods: {
    takeAction(data) {
      this.$emit('display', data)
    },
  },
}
</script>
<style>
.outer {
  @apply py-1 px-2 bg-gray-50 rounded-lg;
}
.outer:hover {
  @apply bg-blue-500;
}
.inner {
  @apply flex justify-between items-center text-gray-500;
}
.outer:hover .inner {
  @apply text-oWhite;
}
.inner-type {
  @apply bg-gray-300  text-gray-500 font-semibold rounded-lg p-1 mr-1;
}
.outer:hover .inner-type {
  @apply bg-oWhite;
}
</style>
