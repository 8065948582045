<template>
  <div class="p-6 flex border-b border-gray-200 hover:bg-gray-100">
    <div class="flex-initial">
      <CustomIcon
        v-if="icon !== ''"
        :icon="icon"
        :customClass="notification.seen ? `opacity-50` : `opacity-100`"
      />
      <span v-else>{{ notification['subcategory'] }}</span>
    </div>
    <div :class="notification['seen'] ? '' : 'font-bold'" class="flex-1  pl-6">
      <div v-html="message"></div>
      <!-- <a href="#" class="text-blue-600">#444320</a> -->
      <small class="text-gray-500">{{
        toDisplayDate(notification['updated_at'], true, true)
      }}</small>
    </div>
    <div>
      <div
        v-if="count > 1"
        :class="
          notification['seen']
            ? 'bg-gray-400 text-white'
            : 'font-bold bg-yellow-400'
        "
        class="p-1 rounded text-center"
        style="height:30px;width:30px"
      >
        {{ count }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import helper from './helper'
import CustomIcon from '@/components/CustomIcon'
export default {
  components: {
    CustomIcon,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    count: Number,
  },
  methods: {
    toDisplayDate: helper.toDisplayDate,
    linkHtml(idType, id, shorten = false) {
      let shortId
      if (typeof id === 'undefined') {
        return '<span class="text-red-600" >Id Unknown</span>'
      }
      if (id === null) {
        return ''
      }
      if (shorten) {
        const idLength = id.length
        shortId = id.slice(idLength > 5 ? id.length - 5 : 0)
      }
      if (idType === 'rider') {
        return `<a  href="/riders/${id}/profile" class="text-blue-600 " target="_blank">#${shortId}</a>`
      }
      if (idType === 'report') {
        return `<a  href="/reports/${id}" class="text-blue-600 " target="_blank">#${shortId}</a>`
      }
      if (idType === 'trip') {
        return `<a  href="/trips/${id}" class="text-blue-600 " target="_blank">#${shortId}</a>`
      }
      if (idType === 'restricted-zone') {
        return `<a  href="/geofence/restricted-areas/${id}" class="text-blue-600 " target="_blank">#${shortId}</a>`
      }
      if (idType === 'service-zone') {
        return `<a  href="/geofence/service-areas/${id}" class="text-blue-600 " target="_blank">#${shortId}</a>`
      }
      if (idType === 'slow-zone') {
        return `<a  href="/geofence/slow-zone-areas/${id}" class="text-blue-600 " target="_blank">#${shortId}</a>`
      }

      // return `<a href="#" class="text-blue-600">#${id}</a>`
    },
    linkHtmlForRider(id, phone) {
      if (typeof id === 'undefined' || typeof phone === 'undefined') {
        return '<span class="text-red-600" >Number Unknown</span>'
      }
      if (id === null || phone === null) {
        return ''
      }

      return `<a  href="/riders/${id}/profile" class="text-blue-600 " target="_blank">${phone}</a>`
    },
    linkHtmlForVehicle(id, qr) {
      if (typeof id === 'undefined' || typeof qr === 'undefined') {
        return '<span class="text-red-600" >Id Unknown</span>'
      }
      if (id === null || qr === null) {
        return ''
      }

      return `<a  href="/vehicle-management/vehicles/${id}/profile" class="text-blue-600 " target="_blank">#${qr}</a>`
    },
    toCurrency(amount) {
      const currencySmbol = this.organizationInfo.default_currency
        ? this.organizationInfo.default_currency.symbol
        : '$'
      if (amount * 1 >= 0) {
        return currencySmbol + '' + amount
      } else {
        return '-' + currencySmbol + '' + (amount * -1).toFixed(2)
      }
    },
    getDocType(type) {
      switch (type) {
        case 'D':
          return 'Driving License'
        case 'P':
          return 'Passport'
        case 'N':
          return 'National ID'
        case 'S':
          return 'Student ID'
        case 'O':
          return 'Official ID'
        default:
          return ''
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    icon() {
      switch (this.notification['subcategory']) {
        case 'IM':
          return 'illegal-movement'
        case 'DM':
          return 'illegal-movement'
        case 'ID':
          return 'illegal-demolished'
        case 'SU':
          return 'suspicious-unlocked'
        case 'IV':
          return 'idle-vehicle'
        case 'CB':
          return 'critical-battery'
        case 'IR':
          return 'iot-removed'
        case 'FD':
          return 'fallen-down'
        case 'PU':
          return 'pull-up'
        case 'LZ':
          return 'low-speed-zone'
        case 'RZ':
          return 'restricted-area'
        case 'OS':
          return 'out-of-service-area'
        case 'PO':
          return 'illegally-parked'
        case 'NB':
          return 'negative-balance'
        case 'UR':
          return 'report-from-user-app'
        case 'VF':
          return 'report-doc-verification'
        default:
          return ''
      }
    },

    message() {
      const notification = this.notification
      if (
        typeof this.notification['extra_data'] !== 'object' ||
        Object.keys(this.notification['extra_data']).length === 0
      ) {
        console.error(
          'There is problem with the notification extra_data',
          notification
        )
      }
      const extraData = this.notification['extra_data']

      const bikeQrCode = this.linkHtmlForVehicle(
        typeof extraData['bike_id'] !== 'undefined'
          ? extraData['bike_id']
          : null,
        typeof extraData['bike_qr_code'] !== 'undefined'
          ? extraData['bike_qr_code']
          : null
      )

      const userId = this.linkHtmlForRider(
        typeof extraData['user_id'] !== 'undefined'
          ? extraData['user_id']
          : null,
        typeof extraData['phone_number'] !== 'undefined'
          ? extraData['phone_number']
          : null
      )
      const fileType = this.getDocType(extraData.card_type)
      switch (notification['subcategory']) {
        case 'IM':
          return `Vehicle ${bikeQrCode} has been Illegally moved (IOT Sensor) by ${
            userId ? userId : 'Unknown'
          }`
        case 'DM':
          return `Vehicle ${bikeQrCode} has been Illegally moved (Distance) by ${
            userId ? userId : 'Unknown'
          }`
        case 'SU':
          return `Vehicle ${bikeQrCode} has been unlocked by an unknown person`
        case 'ID':
          return `Vehicle ${bikeQrCode} has been illegally demolished  by an unknown person`
        case 'IV':
          return `Vehicle ${bikeQrCode} is Inactive`
        case 'CB':
          return `Vehicle ${bikeQrCode} Lock Battery is Critical: ${extraData['power_level']}%`
        case 'IR':
          return `Vehicle ${bikeQrCode}'s iOT has been removed Rider ${userId}`
        case 'FD':
          return `Vehicle ${bikeQrCode} has been Fallen Down by Rider ${userId}`
        case 'PU':
          return `Vehicle ${bikeQrCode} has been Pulled up by Rider ${userId}`
        case 'LZ':
          return `Vehicle ${bikeQrCode} has Entered into Slow Speed Zone Area ${this.linkHtml(
            'slow-zone',
            extraData['area_id'],
            true
          )} by Rider ${userId}`
        case 'RZ':
          return `Vehicle ${bikeQrCode} is inside restricted zone ${this.linkHtml(
            'restricted-zone',
            extraData['area_id'],
            true
          )} `
        case 'OS':
          return `Vehicle ${bikeQrCode} Out of Service Area ${this.linkHtml(
            'service-zone',
            extraData['area_id'],
            true
          )} `
        case 'PO':
          return `Vehicle ${bikeQrCode} is parked outside of parking area in service area ${this.linkHtml(
            'service-zone',
            extraData['area_id'],
            true
          )}`
        case 'NB':
          return `Rider ${userId} balance is negative: ${this.toCurrency(
            extraData['balance']
          )} for trip ${this.linkHtml('trip', extraData['trip_id'], true)}`
        case 'UR':
          return `Report ${this.linkHtml(
            'report',
            extraData['report_id'],
            true
          )} Submitted from App by Rider ${userId}`
        case 'VF':
          return `Rider ${userId} uploaded unverified ${fileType}`
        default:
          return ''
      }
    },
  },
}
</script>
