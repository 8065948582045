var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('STable',{attrs:{"sId-id":_vm.resultType,"headers":_vm.getTableHeaders}},[_vm._l((_vm.serverSearchResult),function(searchItem,searchItemIndex){return [_c('STableRow',{key:searchItemIndex,attrs:{"text-fallback-always":""}},[(!_vm.notShowIdItems.includes(_vm.resultType))?_c('STableRowItem',[_c('div',{staticClass:"flex items-center"},[_c('router-link',{class:"text-blue-600",attrs:{"to":{
                name: _vm.getRouteForId,
                params: { id: searchItem.id },
              },"target":"_blank"}},[_vm._v(" "+_vm._s(searchItem.id.substr(searchItem.id.length - 5))+" ")])],1)]):_vm._e(),(_vm.resultType === 'trip')?_c('STableRowItem',{attrs:{"to":{
            name: 'ViewRiderUserProfile',
            params: { id: searchItem.user.id },
          },"target":"_blank","text":searchItem.user.phone_number}}):_vm._e(),(_vm.resultType === 'trip')?_c('STableRowItem',{attrs:{"text":_vm.getFormattedDateTime(
              searchItem.pick_up_time,
              'D MMM, YY hh:mm:ss a'
            )}}):_vm._e(),(_vm.resultType === 'trip')?_c('STableRowItem',{attrs:{"text":((searchItem.invoice.currency.symbol || '--') + " " + (searchItem
              .invoice.amount || '--'))}}):_vm._e(),(_vm.resultType === 'trip')?_c('STableRowItem',[_c('XStatus',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
              _vm.getTooltipText(
                searchItem.status,
                searchItem.status_forced,
                searchItem.status_forced_by_system
              )
            ),expression:"\n              getTooltipText(\n                searchItem.status,\n                searchItem.status_forced,\n                searchItem.status_forced_by_system\n              )\n            "}],attrs:{"text":_vm.getUserTripStatus(
                searchItem.status,
                searchItem.status_forced,
                searchItem.status_forced_by_system
              ),"variant":_vm.getUserTripStatusBadge(
                searchItem.status,
                searchItem.status_forced,
                searchItem.status_forced_by_system
              ),"profile":"trip"}})],1):_vm._e(),(_vm.resultType === 'vehicle')?_c('STableRowItem',[_c('div',{staticClass:"flex items-center"},[_c('router-link',{class:"text-blue-600",attrs:{"to":{
                name: _vm.getRouteForId,
                params: { id: searchItem.id },
              },"target":"_blank"}},[_vm._v(" "+_vm._s(searchItem.qr_code)+" ")])],1)]):_vm._e(),(_vm.resultType === 'vehicle')?_c('STableRowItem',{attrs:{"text":searchItem.lock.lock_id}}):_vm._e(),(_vm.resultType === 'vehicle')?_c('STableRowItem',[_c('span',{attrs:{"title":_vm.getUTCAwareTime(searchItem.last_connected_at)},domProps:{"textContent":_vm._s(
              _vm.getUTCAwareTime(searchItem.last_connected_at, {
                relative: true,
              })
            )}})]):_vm._e(),(_vm.resultType === 'vehicle')?_c('STableRowItem',[(_vm.getEnabledFlags(searchItem).length > 0)?[_c('div',{staticClass:"flex items-center"},[_vm._l((_vm.getEnabledFlags(searchItem)),function(flag,flagIndex){return [_c('vehicle-flag-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.getFlagAlias(flag, searchItem)),expression:"getFlagAlias(flag, searchItem)",modifiers:{"bottom":true}}],key:("vehicle-" + _vm.itemIndex + "-flag-" + flagIndex),staticClass:"mr-1",attrs:{"size":"sm","name":flag}})]})],2)]:[_vm._v(" No Flags ")]],2):_vm._e(),(_vm.resultType === 'vehicle')?_c('STableRowItem',[_c('VehicleStatus',{attrs:{"data":searchItem,"vehicle-type":"scooter","isShowDropDown":false,"current-status":searchItem.lock.is_locked,"hibernateStatus":searchItem.general_flags.hibernate_mode}})],1):_vm._e(),(_vm.resultType === 'rental')?_c('STableRowItem',{attrs:{"to":{
            name: 'ViewRiderUserProfile',
            params: { id: searchItem.rider.id },
          },"target":"_blank","text":searchItem.rider.phone_number}}):_vm._e(),(_vm.resultType === 'rental')?_c('STableRowItem',{attrs:{"text":_vm.getFormattedDateTime(
              searchItem.start_time,
              'D MMM, YY hh:mm:ss a'
            )}}):_vm._e(),(_vm.resultType === 'rental')?_c('STableRowItem',{attrs:{"text":_vm.getCostLine(searchItem)}}):_vm._e(),(_vm.resultType === 'rental')?_c('STableRowItem',[(!_vm.shouldShowStatusTooltip(searchItem.status))?_c('XStatus',{attrs:{"text":_vm.getRentStatus(searchItem),"variant":_vm.getRentStatusBadge(searchItem),"profile":"trip"}}):_vm._e(),(_vm.shouldShowStatusTooltip(searchItem.status))?_c('XStatus',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.getTooltipTextForRent(searchItem)),expression:"getTooltipTextForRent(searchItem)",modifiers:{"bottom":true}}],attrs:{"text":_vm.getRentStatus(searchItem),"variant":_vm.getRentStatusBadge(searchItem),"profile":"trip"}}):_vm._e()],1):_vm._e(),(_vm.resultType === 'revenue')?_c('STableRowItem',{attrs:{"text-fallback-always":""}},[_c('router-link',{class:"text-blue-600",attrs:{"to":{
              name: searchItem.rental ? 'VehicleRentalDetail' : 'ViewTrip',
              params: {
                id: ("" + (searchItem.rental ? searchItem.rental : searchItem.trip)),
              },
            },"target":"_blank"}},[_vm._v(" "+_vm._s(searchItem.id.substr(searchItem.id.length - 5))+" ")])],1):_vm._e(),(_vm.resultType === 'revenue')?_c('STableRowItem',{attrs:{"text":searchItem.date,"date":true}}):_vm._e(),(_vm.resultType === 'revenue')?_c('STableRowItem',{attrs:{"text":_vm.getPaymentForText('revenue', searchItem.payment_for)}}):_vm._e(),(_vm.resultType === 'revenue')?_c('STableRowItem',{attrs:{"text":searchItem,"as-currency":""}}):_vm._e(),(_vm.resultType === 'revenue')?_c('STableRowItem',[_c('div',{staticClass:"refund-action",class:searchItem.is_refunded ? 'refunded' : 'refund'},[_vm._v(" "+_vm._s(searchItem.is_refunded ? 'Refunded' : 'Refund')+" ")])]):_vm._e(),(_vm.resultType === 'payment')?_c('STableRowItem',{attrs:{"text-fallback-always":""}},[_vm._v(" "+_vm._s(searchItem.id.substr(searchItem.id.length - 5))+" ")]):_vm._e(),(_vm.resultType === 'payment')?_c('STableRowItem',{attrs:{"text":searchItem.date,"date":true}}):_vm._e(),(_vm.resultType === 'payment')?_c('STableRowItem',{attrs:{"text":_vm.getPaymentForText('payment', searchItem.payment_for)}}):_vm._e(),(_vm.resultType === 'payment')?_c('STableRowItem',{attrs:{"text":searchItem,"as-currency":""}}):_vm._e(),(_vm.resultType === 'payment')?_c('STableRowItem',[_c('div',{staticClass:"refund-action",class:searchItem.is_refunded ? 'refunded' : 'refund'},[_vm._v(" "+_vm._s(searchItem.is_refunded ? 'Refunded' : 'Refund')+" ")])]):_vm._e(),(_vm.resultType === 'rider')?_c('STableRowItem',[_c('router-link',{class:"text-blue-600",attrs:{"to":{
              name: 'ViewRiderUserProfile',
              params: { id: searchItem.id },
            },"target":"_blank"}},[_c('div',{staticClass:"flex gap-1"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(searchItem.last_ip_address),expression:"searchItem.last_ip_address",modifiers:{"bottom":true}}]},[_vm._v(_vm._s(searchItem.full_name))]),(searchItem.is_ip_blocked)?_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:("Blacklisted"),expression:"`Blacklisted`",modifiers:{"bottom":true}}],staticClass:"w-4 h-4 ",attrs:{"src":require("@/assets/icon/blacklist_user.svg")}}):_vm._e()])])],1):_vm._e(),(_vm.resultType === 'rider')?_c('STableRowItem',{attrs:{"text":searchItem.phone_number}}):_vm._e(),(_vm.resultType === 'rider')?_c('STableRowItem',[(searchItem.default_card)?_c('div',[_c('i',{class:_vm.getPaymentCardIconClass(searchItem.default_card)}),_c('span',{staticClass:"ml-1"},[_vm._v("**"+_vm._s(searchItem.default_card.number))])]):_c('div',[_vm._v("--")])]):_vm._e(),(_vm.resultType === 'rider')?_c('STableRowItem',[_vm._v(" "+_vm._s(_vm.symbol)+" "+_vm._s(parseFloat(searchItem.balance).toFixed(2))+" ")]):_vm._e(),(_vm.resultType === 'rider')?_c('STableRowItem',[_c('x-status',{attrs:{"text":_vm.getRiderTripStatus(searchItem.last_trip_status),"variant":_vm.getRiderTripStatusBadge(searchItem.last_trip_status),"profile":"trip"}})],1):_vm._e()],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }