var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('form',{staticClass:"pt-6 sm:pb-24 2xl:px-6 2xl:pb-3 sm:px-10",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitStep)}}},[_c('div',[_c('h1',{staticClass:"pb-2"},[_vm._v(_vm._s(_vm.$t( 'components.organizationUsersManagement.addEdit.steps.settings.paymentDetails.subHeading' )))]),_c('label',{staticClass:"flex items-center w-full mt-4 mb-4 cursor-pointer"},[_c('TCheckbox',{model:{value:(_vm.is_not_banking_details_required),callback:function ($$v) {_vm.is_not_banking_details_required=$$v},expression:"is_not_banking_details_required"}}),_c('span',{staticClass:"ml-3 font-semibold select-none text-14p mt-px text-oBlack"},[_vm._v(" "+_vm._s(_vm.$t( 'components.organizationUsersManagement.addEdit.steps.settings.paymentDetails.checkBox.iDontHavePaymentDetails' ))+" ")])],1)]),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-8"},[_c('AppInput',{attrs:{"rules":!_vm.form.paymentInfo.is_banking_details_required ? '' : 'required',"disabled":!_vm.form.paymentInfo.is_banking_details_required,"label":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.paymentDetails.title.bankAccountName'
                ),"placeholder":"e.g Junior Martin"},model:{value:(_vm.form.paymentInfo.account_name),callback:function ($$v) {_vm.$set(_vm.form.paymentInfo, "account_name", $$v)},expression:"form.paymentInfo.account_name"}}),_c('AppInput',{attrs:{"rules":!_vm.form.paymentInfo.is_banking_details_required ? '' : 'required',"disabled":!_vm.form.paymentInfo.is_banking_details_required,"type":"number","label":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.paymentDetails.title.bankAccountNo'
                ),"placeholder":"e.g 110995330"},model:{value:(_vm.form.paymentInfo.account_number),callback:function ($$v) {_vm.$set(_vm.form.paymentInfo, "account_number", $$v)},expression:"form.paymentInfo.account_number"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"placeholder":"e.g. NY City Bank, NY","rules":!_vm.form.paymentInfo.is_banking_details_required ? '' : 'required',"disabled":!_vm.form.paymentInfo.is_banking_details_required,"label":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.paymentDetails.title.branch'
                )},model:{value:(_vm.form.paymentInfo.branch_name),callback:function ($$v) {_vm.$set(_vm.form.paymentInfo, "branch_name", $$v)},expression:"form.paymentInfo.branch_name"}}),_c('AppInput',{attrs:{"name":"Country","label":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.paymentDetails.title.country'
                ),"rules":!_vm.form.paymentInfo.is_banking_details_required ? '' : 'required',"disabled":!_vm.form.paymentInfo.is_banking_details_required,"options":_vm.countries,"valueAttribute":"id","textAttribute":"name","placeholder":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.paymentDetails.placeHolder.country'
                ),"type":"richselect"},on:{"update:options":function($event){_vm.countries=$event}},model:{value:(_vm.form.paymentInfo.country),callback:function ($$v) {_vm.$set(_vm.form.paymentInfo, "country", $$v)},expression:"form.paymentInfo.country"}})],1),_c('AppInput',{attrs:{"placeholder":"e.g. 026013673","rules":!_vm.form.paymentInfo.is_banking_details_required ? '' : 'required',"disabled":!_vm.form.paymentInfo.is_banking_details_required,"label":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.paymentDetails.title.routingNumber'
                ),"type":"number"},model:{value:(_vm.form.paymentInfo.routing_number),callback:function ($$v) {_vm.$set(_vm.form.paymentInfo, "routing_number", $$v)},expression:"form.paymentInfo.routing_number"}}),_c('div',[_c('h1',{staticClass:"pb-2"},[_vm._v(_vm._s(_vm.$t( 'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.subHeading' )))]),_c('label',{staticClass:"flex items-center w-full mt-4 mb-4 cursor-pointer"},[_c('TCheckbox',{model:{value:(_vm.is_not_document_required),callback:function ($$v) {_vm.is_not_document_required=$$v},expression:"is_not_document_required"}}),_c('span',{staticClass:"ml-3 font-semibold select-none text-14p mt-px text-oBlack"},[_vm._v(" "+_vm._s(_vm.$t( 'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.checkBox.iWantToSkipThisStep' ))+" ")])],1)]),_c('div',{staticClass:"flex items-center pt-8"},[_c('AppInput',{attrs:{"rules":!_vm.form.documentInfo.is_document_required ? '' : 'required',"disabled":!_vm.form.documentInfo.is_document_required,"label":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.title.nidNumber'
                ),"placeholder":"e.g. 92344563432"},model:{value:(_vm.form.documentInfo.nid_number),callback:function ($$v) {_vm.$set(_vm.form.documentInfo, "nid_number", $$v)},expression:"form.documentInfo.nid_number"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-8 "},[_c('div',[_c('ValidationProvider',{staticClass:"mt-2",attrs:{"tag":"div","name":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.title.nidFrontSide'
                ),"rules":!_vm.form.documentInfo.is_document_required ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":("NID Front Side " + (!_vm.form.documentInfo.is_document_required ? '' : '*'))}}),_c('UploadImageAlt',{staticClass:"max-h-14",attrs:{"current":_vm.form.documentInfo.nid_front_side_pic,"text":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.uploadImage1.text'
                ),"button-text":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.uploadImage1.buttonText'
                )},model:{value:(_vm.form.documentInfo.nid_front_side_pic),callback:function ($$v) {_vm.$set(_vm.form.documentInfo, "nid_front_side_pic", $$v)},expression:"form.documentInfo.nid_front_side_pic"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1),_c('div',[_c('ValidationProvider',{staticClass:"mt-2",attrs:{"tag":"div","name":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.title.nidFrontSide'
                ),"rules":!_vm.form.documentInfo.is_document_required ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":("NID Back Side " + (!_vm.form.documentInfo.is_document_required ? '' : '*'))}}),_c('UploadImageAlt',{staticClass:"max-h-14",attrs:{"current":_vm.form.documentInfo.nid_back_side_pic,"text":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.uploadImage2.text'
                ),"button-text":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.uploadImage2.buttonText'
                )},model:{value:(_vm.form.documentInfo.nid_back_side_pic),callback:function ($$v) {_vm.$set(_vm.form.documentInfo, "nid_back_side_pic", $$v)},expression:"form.documentInfo.nid_back_side_pic"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_c('div',[_c('label',{staticClass:"flex items-center w-full mt-4 mb-4 cursor-pointer"},[_c('TCheckbox',{attrs:{"disabled":_vm.is_not_document_required},model:{value:(_vm.form.documentInfo.is_verified),callback:function ($$v) {_vm.$set(_vm.form.documentInfo, "is_verified", $$v)},expression:"form.documentInfo.is_verified"}}),_c('span',{class:[
            'ml-3 font-semibold select-none text-14p mt-px',
            _vm.is_not_document_required ? 'text-gray-500' : 'text-oBlack' ]},[_vm._v(" "+_vm._s(_vm.$t( 'components.organizationUsersManagement.addEdit.steps.settings.personalIdentification.checkBox.verified' ))+" ")])],1)]),_c('h1',{staticClass:"pt-12"},[_vm._v(_vm._s(_vm.$t( 'components.organizationUsersManagement.addEdit.steps.settings.drivingLicense.subHeading' )))]),_c('div',{staticClass:"mt-6"},[_c('AppInput',{attrs:{"name":"Driving License","label":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.drivingLicense.title.drivingLicense'
                ),"options":_vm.getLicenseOptions,"text-attribute":"text","value-attribute":"value","hide-search-box":true,"disabled":!_vm.form.documentInfo.is_document_required},model:{value:(_vm.form.documentInfo.has_driving_license),callback:function ($$v) {_vm.$set(_vm.form.documentInfo, "has_driving_license", $$v)},expression:"form.documentInfo.has_driving_license"}})],1),(false)?_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-8"},[_c('div',[_c('input-label',{attrs:{"error":"","text":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.drivingLicense.title.uploadImage1.text'
                )}}),_c('upload-image',{attrs:{"type":"w-full","idx":0,"current":_vm.form.documentInfo.nid_front_side_pic,"title":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.drivingLicense.title.uploadImage1.title'
                ),"disabled":_vm.is_not_document_required},on:{"input":function($event){return _vm.notifyDirty(true)}}})],1),_c('div',[_c('input-label',{attrs:{"error":"","text":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.drivingLicense.title.uploadImage2.text'
                )}}),_c('upload-image',{attrs:{"type":"w-full","idx":1,"current":_vm.form.documentInfo.nid_back_side_pic,"title":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.drivingLicense.title.uploadImage2.title'
                ),"disabled":_vm.is_not_document_required},on:{"input":function($event){return _vm.notifyDirty(true)}}})],1)]):_vm._e(),_c('div',[_c('h1',{staticClass:"pb-2"},[_vm._v(_vm._s(_vm.$t( 'components.organizationUsersManagement.addEdit.steps.settings.currentAddress.subHeading' )))]),_c('label',{staticClass:"flex items-center w-full mt-4 mb-4 cursor-pointer"},[_c('TCheckbox',{model:{value:(_vm.is_not_address_required),callback:function ($$v) {_vm.is_not_address_required=$$v},expression:"is_not_address_required"}}),_c('span',{staticClass:"ml-3 font-semibold select-none text-14p mt-px text-oBlack"},[_vm._v(" "+_vm._s(_vm.$t( 'components.organizationUsersManagement.addEdit.steps.settings.currentAddress.checkBox.iWantToSkipThisStep' ))+" ")])],1)]),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-8"},[_c('AppInput',{attrs:{"name":"Country","label":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.currentAddress.title.country'
                ),"rules":!_vm.form.addressInfo.is_address_required ? '' : 'required',"disabled":!_vm.form.addressInfo.is_address_required,"options":_vm.countries,"valueAttribute":"id","textAttribute":"name","placeholder":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.currentAddress.placeHolder.country'
                ),"type":"richselect"},on:{"update:options":function($event){_vm.countries=$event}},model:{value:(_vm.form.addressInfo.current_address_country),callback:function ($$v) {_vm.$set(_vm.form.addressInfo, "current_address_country", $$v)},expression:"form.addressInfo.current_address_country"}}),_c('AppInput',{attrs:{"rules":!_vm.form.addressInfo.is_address_required ? '' : 'required',"disabled":!_vm.form.addressInfo.is_address_required,"label":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.currentAddress.title.zipCode'
                ),"placeholder":"e.g. 53432"},model:{value:(_vm.form.addressInfo.current_address_zip_code),callback:function ($$v) {_vm.$set(_vm.form.addressInfo, "current_address_zip_code", $$v)},expression:"form.addressInfo.current_address_zip_code"}})],1),_c('AppInput',{attrs:{"name":"Address","label":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.currentAddress.title.address'
                ),"type":"textarea","placeholder":"2307 Oak Street, Old Forge, New York.","disabled":!_vm.form.addressInfo.is_address_required},model:{value:(_vm.form.addressInfo.current_address_full),callback:function ($$v) {_vm.$set(_vm.form.addressInfo, "current_address_full", $$v)},expression:"form.addressInfo.current_address_full"}}),_c('h1',{staticClass:"pt-6"},[_vm._v(_vm._s(_vm.$t( 'components.organizationUsersManagement.addEdit.steps.settings.permanentAddress.subHeading' )))]),_c('label',{staticClass:"flex items-center w-full mt-8 mb-4 cursor-pointer"},[_c('TCheckbox',{attrs:{"disabled":!_vm.form.addressInfo.is_address_required},model:{value:(_vm.permanentAddressIsSame),callback:function ($$v) {_vm.permanentAddressIsSame=$$v},expression:"permanentAddressIsSame"}}),_c('span',{class:[
          'ml-3 font-semibold select-none text-14p mt-px',
          !_vm.form.addressInfo.is_address_required
            ? 'text-gray-500'
            : 'text-oBlack' ]},[_vm._v(" "+_vm._s(_vm.$t( 'components.organizationUsersManagement.addEdit.steps.settings.permanentAddress.checkBox.sameAsCurrentAddress' ))+" ")])],1),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-8"},[_c('AppInput',{attrs:{"name":"Country","label":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.permanentAddress.title.country'
                ),"rules":("" + (!_vm.form.addressInfo.is_address_required || _vm.permanentAddressIsSame
              ? ''
              : 'required')),"options":_vm.countries,"valueAttribute":"id","textAttribute":"name","placeholder":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.permanentAddress.placeHolder.country'
                ),"type":"richselect","disabled":!_vm.form.addressInfo.is_address_required || _vm.permanentAddressIsSame,"variant":{
          readonly: _vm.permanentAddressIsSame,
        }},on:{"update:options":function($event){_vm.countries=$event}},model:{value:(_vm.form.addressInfo.permanent_address_country),callback:function ($$v) {_vm.$set(_vm.form.addressInfo, "permanent_address_country", $$v)},expression:"form.addressInfo.permanent_address_country"}}),_c('AppInput',{attrs:{"rules":("" + (!_vm.form.addressInfo.is_address_required || _vm.permanentAddressIsSame
              ? ''
              : 'required')),"label":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.permanentAddress.title.zipCode'
                ),"placeholder":"e.g 53432","disabled":!_vm.form.addressInfo.is_address_required || _vm.permanentAddressIsSame},model:{value:(_vm.form.addressInfo.permanent_address_zip_code),callback:function ($$v) {_vm.$set(_vm.form.addressInfo, "permanent_address_zip_code", $$v)},expression:"form.addressInfo.permanent_address_zip_code"}})],1),_c('div',[_c('AppInput',{attrs:{"name":"Address","label":_vm.$t(
                  'components.organizationUsersManagement.addEdit.steps.settings.permanentAddress.title.address'
                ),"rules":("" + (!_vm.form.addressInfo.is_address_required || _vm.permanentAddressIsSame
              ? ''
              : 'required')),"placeholder":"2307 Oak Street, Old Forge, New York.","disabled":!_vm.form.addressInfo.is_address_required || _vm.permanentAddressIsSame,"variant":{ readonly: _vm.permanentAddressIsSame }},model:{value:(_vm.form.addressInfo.permanent_address_full),callback:function ($$v) {_vm.$set(_vm.form.addressInfo, "permanent_address_full", $$v)},expression:"form.addressInfo.permanent_address_full"}})],1),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }