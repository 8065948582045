<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form
      @submit.prevent="handleSubmit(submitStep)"
      enctype="multipart/form-data"
      class=" 2xl:px-6 sm:px-1"
    >
      <div class="grid items-center grid-cols-1  my-4 px-5">
        <div class="flex items-center w-full gap-4">
          <upload-avatar
            v-model="form.profile_pic"
            :current="form.profile_pic"
            class=""
          />
          <AppInput
            rules="required"
            :label="
              $t(
                'components.organizationUsersManagement.addEdit.steps.profile.title.fullName'
              )
            "
            placeholder="e.g. Junior Martin"
            v-model="form.full_name"
          />
        </div>
      </div>

      <AppInput
        v-model="form.username"
        rules="required|min:4"
        class="px-5"
        :name="
          $t(
            'components.organizationUsersManagement.addEdit.steps.profile.title.userName'
          )
        "
        :label="
          $t(
            'components.organizationUsersManagement.addEdit.steps.profile.title.userName'
          )
        "
        :placeholder="
          $t(
            'components.organizationUsersManagement.addEdit.steps.profile.placeHolder.userName'
          )
        "
        :disabled="getDisableStatus"
      />
      <AppInput
        rules="required"
        :name="
          $t(
            'components.organizationUsersManagement.addEdit.steps.profile.title.phoneNumber'
          )
        "
        :label="
          $t(
            'components.organizationUsersManagement.addEdit.steps.profile.title.phoneNumber'
          )
        "
        placeholder="e.g 01700000000"
        class="px-5"
        v-model="form.phone_number"
        type="tel"
        :haveSuggestion="true"
        :isSuggestionAlert="isPhoneNumberExist"
        :suggestionNonAlertClass="
          isPhoneNumberChecking
            ? `text-gray-700 font-semibold`
            : `text-green-500 font-semibold`
        "
        :suggestionText="suggestionsForPhoneNumber"
        @input="onChangePhoneNumber"
        @country-changed="onCountryChanged"
      />

      <AppInput
        placeholder="e.g. martin@gmail.com"
        rules="required"
        class="px-5"
        :name="
          $t(
            'components.organizationUsersManagement.addEdit.steps.profile.title.email'
          )
        "
        :label="
          $t(
            'components.organizationUsersManagement.addEdit.steps.profile.title.email'
          )
        "
        v-model="form.email"
        type="email"
        :haveSuggestion="true"
        :isSuggestionAlert="isEmailExist"
        :suggestionNonAlertClass="
          isEmailChecking
            ? `text-gray-700 font-semibold`
            : `text-green-500 font-semibold`
        "
        :suggestionText="suggestionsForEmail"
        @input="onChangeEmail"
      />

      <AppInput
        v-if="!isEditing"
        class="px-5"
        v-model="form.password"
        :type="'password'"
        :name="
          $t(
            'components.organizationUsersManagement.addEdit.steps.profile.title.password'
          )
        "
        :label="
          $t(
            'components.organizationUsersManagement.addEdit.steps.profile.title.password'
          )
        "
        :rules="`${isEditing ? '' : 'required|min:6'}`"
        :disabled="isEditing"
      />

      <AppInput
        v-model="form.assigned_fleets"
        class="px-5"
        rules="required"
        type="richselect"
        :name="
          $t(
            'components.organizationUsersManagement.addEdit.steps.profile.title.assignedFleets'
          )
        "
        :label="
          $t(
            'components.organizationUsersManagement.addEdit.steps.profile.title.assignedFleets'
          )
        "
        :options="fleets"
        :text-attribute="`name`"
        :value-attribute="`id`"
        :hide-search-box="false"
        :placeholder="
          $t(
            'components.organizationUsersManagement.addEdit.steps.profile.placeHolder.assignedFleets'
          )
        "
        multiple
      />

      <AppInput
        v-model="form.preferred_currency"
        class="px-5"
        rules="required"
        type="richselect"
        :name="
          $t(
            'components.organizationUsersManagement.addEdit.steps.profile.title.preferredCurrency'
          )
        "
        :label="
          $t(
            'components.organizationUsersManagement.addEdit.steps.profile.title.preferredCurrency'
          )
        "
        :options="currencies"
        :text-attribute="`name`"
        :value-attribute="`id`"
        :hide-search-box="false"
        :placeholder="
          $t(
            'components.organizationUsersManagement.addEdit.steps.profile.placeHolder.preferredCurrency'
          )
        "
      />

      <div class="grid grid-cols-2 gap-4 mt-2 px-5">
        <AppInput
          v-model="form.gender"
          :type="`richselect`"
          :label="
            $t(
              'components.organizationUsersManagement.addEdit.steps.profile.title.gender'
            )
          "
          :options="getGenders"
          :text-attribute="`text`"
          :value-attribute="`value`"
          :hide-search-box="true"
          :placeholder="
            $t(
              'components.organizationUsersManagement.addEdit.steps.profile.placeHolder.gender'
            )
          "
        />

        <AppInput
          type="date"
          :placeholder="
            $t(
              'components.organizationUsersManagement.addEdit.steps.profile.placeHolder.dateOfBirth'
            )
          "
          :label="
            $t(
              'components.organizationUsersManagement.addEdit.steps.profile.title.dateOfBirth'
            )
          "
          :max="maxBirthDate"
          v-model="form.birthday"
        />
      </div>

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import PhoneNumber from 'awesome-phonenumber'
import { deepCompareObjects } from '@/utils'
import { OrganizationUserConfig } from '@/config/OrganizationUserConfig'
import debounce from 'lodash.debounce'
import UploadAvatar from '@/components/form/UploadAvatar'
// import InputLabel from '@/components/form/InputLabel'
import { useEndpoints } from '@/composables'
// import TextInput from '@/components/form/TextInput'

export default {
  name: 'Step1',
  components: {
    UploadAvatar,
    // InputLabel,
    // TextInput,
  },
  props: {
    fleets: {
      required: true,
    },
    currencies: {
      required: true,
    },
    userId: {
      type: null,
      required: false,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created() {
    this.maxDatecalculation()
  },
  data() {
    return {
      isLoading: false,
      isPhoneNumberChecking: false,
      isPhoneNumberExist: false,
      suggestionsForPhoneNumber: '',
      currentPhoneNumber: null,
      isEmailChecking: false,
      isEmailExist: false,
      suggestionsForEmail: '',
      currentEmail: null,
      form: {
        profile_pic: '',
        ranger_job_type: 'FULLTIME',
        full_name: '',
        username: '',
        country_code: '',
        phone_number: '',
        email: '',
        password: '',
        birthday: '1971-12-16',
        gender: 'M',
        assigned_fleets: [],
        preferred_currency: null,
      },

      show_password: false,
      selFleets: [],
      is_change_password: false,
      change_edit: false,
      error_exist_phone_number: '',
      maxBirthDate: '',
      phoneNoInfo: null,
    }
  },
  computed: {
    getJobTypes: () => [
      { text: 'Full Time', value: 'FULLTIME' },
      { text: 'Part Time', value: 'PARTTIME' },
    ],
    getGenders: () => [
      { text: 'Male', value: 'M' },
      { text: 'Female', value: 'F' },
      { text: 'Others', value: 'O' },
    ],
    getDisableStatus() {
      if (this.$user?.is_superadmin) {
        return false
      } else {
        return this.form.role_name === 'Owner'
      }
    },
  },
  watch: {
    // sync props.formData with $data.from
    formData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data) {
          this.form = { ...data }
          this.currentPhoneNumber = data.phone_number
          this.currentEmail = data.email
        }
      },
    },
    // notify form is dirty & user should confirm before exiting
    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        this.$emit('dirty', { step: 1, data: this.form })
      },
    },
  },
  methods: {
    maxDatecalculation() {
      const date = new Date()
      const maxDate = date - 1000 * 60 * 60 * 24 * 4383 // current date's milliseconds - 1,000 ms * 60 s * 60 mins * 24 hrs * (# of days beyond one to go back)
      this.maxBirthDate = new Date(maxDate).toISOString().slice(0, 10)
    },
    onCountryChanged(e) {
      // alert(e.dialCode)
      console.log('phoneNoInfo', e)
      this.phoneNoInfo = e
    },

    saveCountryCode(countrycode) {
      this.form.country_code = countrycode
    },
    async submit() {
      this.$refs.submitButton.click()
    },
    onChangePhoneNumber(e) {
      const phoneNumber = PhoneNumber(e, this.phoneNoInfo.iso2).getNumber(
        'e164'
      )
      if (phoneNumber && phoneNumber !== this.currentPhoneNumber) {
        this.onCheckPhoneNumber(phoneNumber)
      } else {
        this.isPhoneNumberExist = false
        this.suggestionsForPhoneNumber = ''
      }
    },
    onCheckPhoneNumber: debounce(function(e) {
      this.isPhoneNumberExist = false
      this.isPhoneNumberChecking = true
      this.suggestionsForPhoneNumber = 'Checking...'
      this.$http
        .get(
          useEndpoints.user.org.checkPhoneNumberExistence(e.replace('+', '%2b'))
        )
        .then((res) => {
          this.isPhoneNumberExist = res.data.phone_number_exists
          if (this.isPhoneNumberExist) {
            this.suggestionsForPhoneNumber = 'The Number is already exist'
          } else {
            this.suggestionsForPhoneNumber = 'This number is available.'
          }
        })
        .catch((err) => console.log('onCheckPhone-err', err.response))
        .finally(() => {
          this.isPhoneNumberChecking = false
        })
    }, 2000),
    onChangeEmail(e) {
      console.log('onChangeEmail', e, this.currentEmail)
      if (e && e !== this.currentEmail) {
        this.onCheckEmail(e)
      } else {
        this.isEmailExist = false
        this.suggestionsForEmail = ''
      }
    },
    onCheckEmail: debounce(function(e) {
      this.isEmailExist = false
      this.isEmailChecking = true
      this.suggestionsForEmail = 'Checking...'
      this.$http
        .get(useEndpoints.user.org.checkEmailExistence(e.replace('+', '%2b')))
        .then((res) => {
          this.isEmailExist = res.data.email_exists
          if (this.isEmailExist) {
            this.suggestionsForEmail = 'The Email is already exist'
          } else {
            this.suggestionsForEmail = 'The Email is available'
          }
        })
        .catch((err) => console.log('onCheckEmail-err', err.response))
        .finally(() => {
          this.isEmailChecking = false
        })
    }, 2000),
    async submitStep() {
      // if it's in add mode, user shouldn't be able to re submit step 1
      // or if the api supports it, it needs to be a patch request
      // if theUserId is already set & mode is strict -> it means user is trying
      // to get back to step 1 & resubmit
      const formDataProxy = { ...this.form }
      formDataProxy.ranger_job_type = 'FULLTIME'
      if (this.isEditing) delete formDataProxy.password

      const method = this.isEditing ? 'PATCH' : 'POST'
      const url = this.isEditing
        ? OrganizationUserConfig.api.update(this.userId)
        : OrganizationUserConfig.api.create

      if (
        formDataProxy.profile_pic == null ||
        typeof formDataProxy.profile_pic === 'string'
      ) {
        delete formDataProxy.profile_pic
      }

      let data = new FormData()

      if (formDataProxy.profile_pic)
        data.append('profile_pic', formDataProxy.profile_pic)

      data.append('full_name', formDataProxy.full_name)

      const phoneNo = PhoneNumber(
        formDataProxy.phone_number,
        this.phoneNoInfo.iso2
      ).getNumber('e164')

      console.log(
        'phoneNo',
        formDataProxy.phone_number,
        this.form.phone_number,
        phoneNo
      )
      data.append('phone_number', phoneNo)

      data.append('email', formDataProxy.email)
      data.append('birthday', formDataProxy.birthday)
      data.append('gender', formDataProxy.gender)
      data.append('ranger_job_type', formDataProxy.ranger_job_type)
      formDataProxy.password && data.append('password', formDataProxy.password)

      if (formDataProxy?.assigned_fleets?.length) {
        formDataProxy.assigned_fleets.forEach((item) => {
          data.append('assigned_fleets', item)
        })
      }

      data.append('preferred_currency', formDataProxy.preferred_currency)
      data.append('username', formDataProxy.username)

      this.isLoading = true
      await this.$http({ method, url, data })
        .then((res) => {
          this.$emit('save', { step: 1, data: res.data })

          const message = `Organization User ${
            this.isEditing ? 'updated' : 'added'
          } successfully`

          this.$notify(
            {
              group: 'important',
              type: 'success',
              title: 'Success',
              text: message,
            },
            5000
          )
        })
        .catch((err) => {
          console.log('errorOccurred', err.response)
          // todo: emit error
          this.$notify(
            {
              group: 'important',
              type: 'error',
              title: 'Error occurred!',
              text: err.response.data.message ?? err.response.data,
            },
            12000
          )
          console.warn(err)
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>
