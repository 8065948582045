<template>
  <div class="flex">
    <div class="flex items-center justify-center w-12 bg-gray-700">
      <svg
        class="w-6 h-6 text-gray-100"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
        ></path>
      </svg>
    </div>

    <div class="px-4 py-2 -mx-3">
      <div class="mx-3">
        <span class="font-semibold text-gray-800">{{
          notification.title
        }}</span>
        <p class="text-sm text-gray-600">{{ notification.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToastNotificationDefault',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
}
</script>
