<template>
  <base-layout>
    <BillingPlan :pageTitle="$t('components.billingPlanManagement.headline')" />
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import BillingPlan from '@/views/pricing/billing-plan/index.vue'

export default {
  name: 'BillingPlans',

  components: {
    BaseLayout,
    BillingPlan,
  },
}
</script>
