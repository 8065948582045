<template>
  <!-- start: whatsnew-dropdown-trigger -->
  <div ref="whatsnewContainer">
    <div
      @click="open = !open"
      class="flex justify-center items-center px-4 border-l cursor-pointer hover:bg-green-200 w-55px h-55px"
    >
      <div class="relative ">
        <img src="@/assets/oto/lightning.png" alt="" srcset="" class="" />
        <div
          class="absolute w-2 h-2 rounded-full bg-green-600"
          style="top: -3px; right: -2.5px"
        ></div>
      </div>
    </div>
    <div
      v-if="open"
      ref="backdrop"
      class="fixed right-0 w-full"
      style="top:55px;height: calc(100vh - 55px); width: 100vw; background-color: rgb(3 3 3 / 32%)"
    >
      <div
        class="fixed right-0 bg-white shadow-md notificationDropdown"
        style="height: calc(100vh - 55px)"
      >
        <div class="flex justify-between items-center">
          <h1 class="p-5 font-semibold section-title text-28px">
            Release Notes
          </h1>
        </div>
        <div class=" overflow-auto" style="height: calc(100vh - 110px )">
          <loading :active.sync="isLoaded" :is-full-page="false" />
          <WhatsNewItem :rawData="resData" />
          <infinite-loading @infinite="fetchWhatsNewData" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WhatsNewConfig } from '@/config/WhatsNewConfig.js'
export default {
  components: {
    InfiniteLoading: () => import('vue-infinite-loading'),
    WhatsNewItem: () =>
      import(
        '@/components/layout/topbar/whatsnew-dropdown-trigger/WhatsNewItem.vue'
      ),
  },
  async created() {
    await this.fetchWhatsNewData()
  },
  mounted() {
    document.addEventListener('click', this.autoCloseEventListener)
  },
  data() {
    return {
      open: false,
      loadOnOpen: true,
      isLoaded: false,
      resultLimit: 10,
      resData: [],
    }
  },
  methods: {
    autoCloseEventListener(e) {
      // trigger if mouse is clicked outside of notifcation
      const whatsnewContainer = this.$refs.whatsnewContainer
      if (
        (typeof whatsnewContainer !== 'undefined' &&
          whatsnewContainer !== e.target &&
          !whatsnewContainer.contains(e.target)) ||
        e.target === this.$refs.backdrop
      ) {
        this.open = false
        console.log('hit-close')
      }
    },
    async fetchWhatsNewData($state = null) {
      this.isLoaded = true
      await this.$http
        .get(this.getEndpoint)
        .then((res) => {
          console.log('res = ', res.data)
          if (this.resData.length) {
            this.resData = this.resData.concat(res.data?.data)
            // console.log('Loaded later', res.data?.data.length)
          } else {
            this.resData = res.data?.data
            // console.log('Loaded logs', this.logs.length)
            // if (!this.isLoaded) this.isLoaded = true
          }

          if ($state) {
            if (res?.data?.meta?.count?.total > this.resData.length) {
              $state.loaded()
            } else {
              $state.complete()
            }
          }
        })
        .catch((err) => {
          console.log('err = ', err.response.data)
        })
        .finally(() => (this.isLoaded = false))
    },
  },
  computed: {
    getLimit() {
      return 10
    },
    getOffset() {
      return this.resData.length
    },
    getEndpoint() {
      console.log('getEndpoint', this.getOffset)
      return (
        WhatsNewConfig.api.systemVersion.index() +
        `?limit=${this.getLimit}&offset=${this.getOffset}`
      )
    },
  },
  destroy() {
    document.removeEventListener('click', this.autoCloseEventListener)
  },
}
</script>
<style>
.notificationDropdown {
  width: 100%;
}

@media screen and (min-width: 640px) {
  .notificationDropdown {
    width: 640px;
  }
}
</style>
