var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"notificationContainer"},[_c('div',{staticClass:"flex justify-center items-center px-4 border-l cursor-pointer hover:bg-gray-300 w-55px h-55px",on:{"click":function($event){_vm.open = !_vm.open}}},[_c('div',{staticClass:"relative rounded-lg border border-gray-700"},[_c('div',{staticClass:"absolute w-2 h-2 rounded-full bg-red-notification",staticStyle:{"top":"-2px","right":"-2.5px"}}),_c('span',{staticClass:"p-2 text-xs text-red-notification"},[_vm._v(_vm._s(_vm.unseenCount))])])]),(_vm.open)?_c('div',{ref:"backdrop",staticClass:"fixed right-0 w-full",staticStyle:{"top":"55px","height":"calc(100vh - 55px)","width":"100vw","background-color":"rgb(3 3 3 / 32%)"}},[_c('div',{staticClass:"fixed right-0 bg-white shadow-md notificationDropdown",staticStyle:{"height":"calc(100vh - 55px)"}},[_c('div',{staticClass:"flex justify-between items-center"},[_c('h1',{staticClass:"p-5 font-semibold section-title text-28px"},[_vm._v(" Notification ")]),_c('section',{staticClass:"flex items-center"},[_c('router-link',{class:"text-blue-600 mr-5",attrs:{"to":{ name: 'NotificationIndex', params: {} },"target":"_blank"}},[_vm._v(" View Details ")])],1)]),_c('div',{staticClass:"pt-4"},[_c('div',{staticClass:"flex px-5 mb-2"},[_c('div',{staticClass:"flex-1 pb-2 text-center cursor-pointer",class:_vm.categoryFilter === ''
                ? 'border-b-2 border-black'
                : 'text-gray-400',on:{"click":function($event){_vm.categoryFilter = ''}}},[_vm._v(" All ")]),_c('div',{staticClass:"flex-1 pb-2 text-center cursor-pointer",class:_vm.categoryFilter === 'V'
                ? 'border-b-2 border-black'
                : 'text-gray-400',on:{"click":function($event){_vm.categoryFilter = 'V'}}},[_vm._v(" Vehicle ")]),_c('div',{staticClass:"flex-1 pb-2 text-center cursor-pointer",class:_vm.categoryFilter === 'A'
                ? 'border-b-2 border-black'
                : 'text-gray-400',on:{"click":function($event){_vm.categoryFilter = 'A'}}},[_vm._v(" Area ")]),_c('div',{staticClass:"flex-1 pb-2 text-center cursor-pointer",class:_vm.categoryFilter === 'W'
                ? 'border-b-2 border-black'
                : 'text-gray-400',on:{"click":function($event){_vm.categoryFilter = 'W'}}},[_vm._v(" eWallet ")]),_c('div',{staticClass:"flex-1 pb-2 text-center cursor-pointer",class:_vm.categoryFilter === 'R'
                ? 'border-b-2 border-black'
                : 'text-gray-400',on:{"click":function($event){_vm.categoryFilter = 'R'}}},[_vm._v(" Report ")])]),(
            _vm.stickyDateIndex >= 0 &&
              _vm.groupedByDateNotificationShowChildrenCount[_vm.activeStickyDate] * 1
          )?_c('div',{staticClass:"py-2 px-5 font-bold text-gray-500 bg-gray-200"},[_vm._v(" "+_vm._s(_vm.toDisplayDate(_vm.activeStickyDate))+" ")]):_vm._e(),_c('div',{ref:"notificationListContainer",staticClass:"overflow-y-auto",staticStyle:{"height":"calc(100vh - 55px - 82px - 60px - 40px)"},on:{"scroll":_vm.notificationListScrolling}},[_vm._l((_vm.sortedNotificationGroupDates),function(date,index){return [_c('NotificationGroup',{key:'notif' + index,ref:'sortedNotificationGroupDates' + index,refInFor:true,attrs:{"date":date,"notifications":_vm.groupedByDateNotificationLookUp[date],"category-filter":_vm.categoryFilter,"notification-list-look-up":_vm.notificationListLookUp,"grouped-notification-look-up":_vm.groupedNotificationLookUp,"show-header":index !== _vm.stickyDateIndex},on:{"children-count-change":function($event){return _vm.childrenCountChange(date, $event)}}})]}),_c('div',{staticClass:"py-2 text-center"},[(_vm.hasLoadMore)?_c('button',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
                callback: _vm.notificationListObserver,
                once: false,
              }),expression:"{\n                callback: notificationListObserver,\n                once: false,\n              }"}],staticClass:"py-1 px-6 bg-gray-200 rounded-full shadow-none",on:{"click":_vm.getNotifications}},[_vm._v(" Load More "),_c('i',{staticClass:"ml-1 fas fa-arrow-down"})]):_vm._e()]),_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}})],2)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }