<template>
  <ValidationObserver ref="observerRef" v-slot="{ handleSubmit }">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <div class="flex items-start">
        <div class="w-1/2">
          <AppInput
            v-model="form.name"
            type="text"
            rules="required"
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.pricingName'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.pricingName'
              )
            "
            :infoDescription="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.infoDescription.pricingName'
              )
            "
          />
        </div>

        <div class="w-1/2 pl-2">
          <AppInput
            v-model="form.bike_category"
            type="richselect"
            rules="required"
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.vehicleType'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.vehicleType'
              )
            "
            :infoDescription="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.infoDescription.vehicleType'
              )
            "
            :placeholder="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.placeHolder.vehicleType'
              )
            "
            value-attribute="value"
            text-attribute="text"
            :options="vehicleTypes"
            hide-search-box
          />
        </div>
      </div>

      <div class="flex items-start">
        <div class="w-1/2 ">
          <AppInput
            v-model="form.fleet"
            type="richselect"
            rules="required"
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.fleet'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.fleet'
              )
            "
            :infoDescription="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.infoDescription.fleet'
              )
            "
            :placeholder="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.placeHolder.fleet'
              )
            "
            value-attribute="id"
            text-attribute="name"
            :options="fleets"
            hide-search-box
          />
        </div>

        <div class="w-1/2 pl-2">
          <AppInput
            rules=""
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.fleetCurrency'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.fleetCurrency'
              )
            "
            :isInfoEnabled="false"
            :value="
              selectedFleetCurrency ||
                $t(
                  'components.billingPlanManagement.addEdit.steps.ride.placeHolder.fleet'
                )
            "
            disabled
          />
        </div>
      </div>

      <div class="flex items-start py-4">
        <ValidationProvider
          vid="tax_enabled"
          name=" "
          rules=""
          v-slot="{ errors }"
        >
          <input-label
            :error="errors[0]"
            :text="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.taxPlan'
              )
            "
          />
          <div class="flex">
            <label class="flex items-center mt-px ml-2 cursor-pointer">
              <input
                v-model="form.tax_enabled"
                type="radio"
                class="p-1 form-radio"
                name="form.tax_enabled"
                :value="true"
              />
              <span class="ml-2 text-sm">{{
                $t(
                  'components.billingPlanManagement.addEdit.steps.ride.title.yes'
                )
              }}</span>
            </label>

            <label class="flex items-center mt-px ml-4 cursor-pointer">
              <input
                v-model="form.tax_enabled"
                type="radio"
                class="p-1 form-radio"
                name="form.tax_enabled"
                :value="false"
              />
              <span class="ml-2 text-sm">
                {{
                  $t(
                    'components.billingPlanManagement.addEdit.steps.ride.title.no'
                  )
                }}
              </span>
            </label>
          </div>
        </ValidationProvider>
      </div>

      <div class="flex items-start mt-5" v-if="form.tax_enabled">
        <div class="w-full ">
          <AppInput
            v-model="form.tax_plan"
            type="richselect"
            :rules="form.tax_enabled ? 'required' : ''"
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.chooseTaxPlan'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.chooseTaxPlan'
              )
            "
            :infoDescription="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.infoDescription.chooseTaxPlan'
              )
            "
            :placeholder="getTaxPlanPlaceholder"
            value-attribute="value"
            text-attribute="text"
            :disabled="getTaxPlanDisabledState"
            :options="modifiedTaxPlan"
            hide-search-box
          />
          <p
            v-if="!isTaxPlanLoading && taxPlans.length === 0"
            class="text-sm text-gray-500"
          >
            <RouterLink
              :to="{
                name: 'ViewTaxPlan',
              }"
              target="_blank"
              class="text-blue-600 capitalize font-semibold"
            >
              {{
                $t(
                  'components.billingPlanManagement.addEdit.steps.ride.placeHolder.chooseTaxPlan.createTaxPlan'
                )
              }}
            </RouterLink>
            {{
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.placeHolder.chooseTaxPlan.atFirst'
              )
            }}
          </p>
        </div>
      </div>

      <div class="flex items-start">
        <div class="w-1/2 ">
          <AppInput
            v-model.number="form.vehicle_reservation_fees"
            type="number"
            step="0.01"
            rules="required|between:0,1000"
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.vehicleReservationPrice'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.vehicleReservationPrice'
              )
            "
            :infoDescription="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.infoDescription.vehicleReservationPrice'
              )
            "
            :haveSuggestion="form.tax_enabled"
            :suggestionText="
              getVatIncludedAmount(form.vehicle_reservation_fees)
            "
            :haveUnitText="true"
            :unitText="selectedFleetCurrencyFormatted"
          />
        </div>

        <div class="w-1/2 pl-2">
          <AppInput
            v-model.number="form.vehicle_unlock_fees"
            type="number"
            step="0.01"
            rules="required"
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.unlockCharge'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.unlockCharge'
              )
            "
            :infoDescription="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.infoDescription.unlockCharge'
              )
            "
            :haveSuggestion="form.tax_enabled"
            :suggestionText="getVatIncludedAmount(form.vehicle_unlock_fees)"
            :haveUnitText="true"
            :unitText="selectedFleetCurrencyFormatted"
          />
        </div>
      </div>

      <!-- uc -->

      <div class="flex items-start">
        <div class="w-1/2">
          <AppInput
            v-model="form.billing_unit_mins"
            type="number"
            rules="required"
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.billingUnitMins'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.billingUnitMins'
              )
            "
            :infoDescription="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.infoDescription.billingUnitMins'
              )
            "
            :haveUnitText="true"
            unitText="Mins"
          />
        </div>
        <div class="w-1/2 pl-2">
          <AppInput
            v-model="form.first_unit_fees"
            type="number"
            rules="required"
            step="0.01"
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.firstUnitFees'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.firstUnitFees'
              )
            "
            :infoDescription="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.infoDescription.firstUnitFees'
              )
            "
            :haveSuggestion="form.tax_enabled"
            :suggestionText="getVatIncludedAmount(form.first_unit_fees)"
            :haveUnitText="true"
            :unitText="selectedFleetCurrencyFormatted"
          />
        </div>
      </div>

      <div class="flex items-start">
        <div class="w-1/2 ">
          <AppInput
            v-model="form.default_unit_fees"
            type="number"
            rules="required"
            step="0.01"
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.defaultUnitFees'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.defaultUnitFees'
              )
            "
            :infoDescription="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.infoDescription.defaultUnitFees'
              )
            "
            :haveSuggestion="form.tax_enabled"
            :suggestionText="getVatIncludedAmount(form.default_unit_fees)"
            :haveUnitText="true"
            :unitText="selectedFleetCurrencyFormatted"
          />
        </div>
        <div class="w-1/2 pl-2">
          <AppInput
            v-model="form.max_billing_per_ride"
            type="number"
            rules="required"
            step="0.01"
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.maxBillingPerRide'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.maxBillingPerRide'
              )
            "
            :infoDescription="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.infoDescription.maxBillingPerRide'
              )
            "
            :haveSuggestion="form.tax_enabled"
            :suggestionText="getVatIncludedAmount(form.max_billing_per_ride)"
            :haveUnitText="true"
            :unitText="selectedFleetCurrencyFormatted"
          />
        </div>
      </div>
      <div class="flex items-start">
        <div class="w-full">
          <AppInput
            v-model="form.minimum_ride_fee"
            type="number"
            step="0.01"
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.minBillingPerRide'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.minBillingPerRide'
              )
            "
            :infoDescription="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.infoDescription.minBillingPerRide'
              )
            "
            :haveSuggestion="form.tax_enabled"
            :suggestionText="getVatIncludedAmount(form.minimum_ride_fee)"
            :haveUnitText="true"
            :unitText="selectedFleetCurrencyFormatted"
          />
        </div>
      </div>

      <div class="flex items-start">
        <div class="w-full">
          <AppInput
            v-model="form.minimum_trip_balance"
            type="number"
            step="0.01"
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.minWalletBalanceShouldTakeTrip'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.minWalletBalanceShouldTakeTrip'
              )
            "
            :infoDescription="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.infoDescription.minWalletBalanceShouldTakeTrip'
              )
            "
            :haveSuggestion="form.tax_enabled"
            :suggestionText="getVatIncludedAmount(form.minimum_trip_balance)"
            :haveUnitText="true"
            :unitText="selectedFleetCurrencyFormatted"
          />
        </div>
      </div>

      <div class="flex items-center py-2">
        <ValidationProvider vid="status" name=" " rules="" v-slot="{ errors }">
          <input-label
            :error="errors[0]"
            :text="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.status'
              )
            "
          />
          <div class="flex">
            <label class="flex items-center mt-px ml-2 cursor-pointer">
              <input
                type="radio"
                class="p-1 form-radio"
                name="status"
                v-model="form.status_active"
                :value="true"
              />
              <span class="ml-2 text-sm">{{
                $t(
                  'components.billingPlanManagement.addEdit.steps.ride.title.active'
                )
              }}</span>
            </label>

            <label class="flex items-center mt-px ml-4 cursor-pointer">
              <input
                type="radio"
                class="p-1 form-radio"
                name="status"
                v-model="form.status_active"
                :value="false"
              />
              <span class="ml-2 text-sm">{{
                $t(
                  'components.billingPlanManagement.addEdit.steps.ride.title.inactive'
                )
              }}</span>
            </label>
          </div>
        </ValidationProvider>
      </div>

      <div class="flex items-start py-2">
        <ValidationProvider
          vid="pause_ride_fee_same_as_billing"
          name=" "
          rules=""
          v-slot="{ errors }"
        >
          <input-label
            :error="errors[0]"
            :text="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.pauseRideFeeSameAsAbove'
              )
            "
          />
          <div class="flex">
            <label class="flex items-center mt-px ml-2 cursor-pointer">
              <input
                type="radio"
                class="p-1 form-radio"
                name="pause_ride_fee_same_as_billing"
                v-model="form.pause_ride_fee_same_as_billing"
                :value="true"
              />
              <span class="ml-2 text-sm">
                {{
                  $t(
                    'components.billingPlanManagement.addEdit.steps.ride.title.yes'
                  )
                }}
              </span>
            </label>

            <label class="flex items-center mt-px ml-4 cursor-pointer">
              <input
                type="radio"
                class="p-1 form-radio"
                name="pause_ride_fee_same_as_billing"
                v-model="form.pause_ride_fee_same_as_billing"
                :value="false"
              />
              <span class="ml-2 text-sm">{{
                $t(
                  'components.billingPlanManagement.addEdit.steps.ride.title.no'
                )
              }}</span>
            </label>
          </div>
        </ValidationProvider>
      </div>

      <div class="flex items-start" v-if="!form.pause_ride_fee_same_as_billing">
        <div class="w-1/2">
          <AppInput
            v-model="form.tax_applies_to_pause_fees"
            type="richselect"
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.taxAppliedOnPauses'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.taxAppliedOnPauses'
              )
            "
            :infoDescription="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.infoDescription.taxAppliedOnPauses'
              )
            "
            :placeholder="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.placeHolder.taxAppliedOnPauses'
              )
            "
            value-attribute="value"
            text-attribute="text"
            :options="[
              { text: 'Yes', value: true },
              { text: 'No', value: false },
            ]"
            hide-search-box
          />
        </div>
        <div class="w-1/2 pl-2">
          <AppInput
            v-model="form.pause_billing_unit_mins"
            type="number"
            rules="required"
            step="1"
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.pauseBillingUnitMins'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.pauseBillingUnitMins'
              )
            "
            :infoDescription="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.infoDescription.pauseBillingUnitMins'
              )
            "
            :haveUnitText="true"
            unitText="Mins"
          />
        </div>
      </div>

      <div class="flex items-start" v-if="!form.pause_ride_fee_same_as_billing">
        <div class="w-1/2">
          <AppInput
            v-model="form.pause_first_unit_fees"
            type="number"
            rules="required"
            step="0.01"
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.pauseFirstUnitFees'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.pauseFirstUnitFees'
              )
            "
            :infoDescription="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.infoDescription.pauseFirstUnitFees'
              )
            "
            :haveSuggestion="form.tax_enabled && form.tax_applies_to_pause_fees"
            :suggestionText="getVatIncludedAmount(form.pause_first_unit_fees)"
            :haveUnitText="true"
            :unitText="selectedFleetCurrencyFormatted"
          />
        </div>
        <div class="w-1/2 pl-2">
          <AppInput
            v-model="form.pause_default_unit_fees"
            type="number"
            rules="required"
            step="0.01"
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.pauseNextUnitFees'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.pauseNextUnitFees'
              )
            "
            :infoDescription="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.infoDescription.pauseNextUnitFees'
              )
            "
            :haveSuggestion="form.tax_enabled && form.tax_applies_to_pause_fees"
            :suggestionText="getVatIncludedAmount(form.pause_default_unit_fees)"
            :haveUnitText="true"
            :unitText="selectedFleetCurrencyFormatted"
          />
        </div>
      </div>

      <div class="flex items-center py-2">
        <ValidationProvider
          vid="is_pre_auth_required"
          name=" "
          rules=""
          v-slot="{ errors }"
        >
          <input-label
            :error="errors[0]"
            :text="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.preAuthRequired'
              )
            "
          />
          <div class="flex">
            <label class="flex items-center mt-px ml-2 cursor-pointer">
              <input
                type="radio"
                class="p-1 form-radio"
                name="is_pre_auth_required"
                v-model="form.is_pre_auth_required"
                :value="true"
              />
              <span class="ml-2 text-sm">{{
                $t(
                  'components.billingPlanManagement.addEdit.steps.ride.title.yes'
                )
              }}</span>
            </label>

            <label class="flex items-center mt-px ml-4 cursor-pointer">
              <input
                type="radio"
                class="p-1 form-radio"
                name="is_pre_auth_required"
                v-model="form.is_pre_auth_required"
                :value="false"
              />
              <span class="ml-2 text-sm">{{
                $t(
                  'components.billingPlanManagement.addEdit.steps.ride.title.no'
                )
              }}</span>
            </label>
          </div>
        </ValidationProvider>
      </div>

      <div class="flex items-start py-2" v-if="form.is_pre_auth_required">
        <div class="w-1/2 pl-2">
          <AppInput
            v-model="form.pre_auth_hold_amount"
            type="number"
            rules="required"
            step="0.01"
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.preAuthHoldAmount'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.preAuthHoldAmount'
              )
            "
            :infoDescription="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.infoDescription.preAuthHoldAmount'
              )
            "
            :haveUnitText="true"
            :unitText="selectedFleetCurrencyFormatted"
          />
        </div>
        <div class="w-1/2 pl-2">
          <AppInput
            v-model="form.pre_auth_ride_condition"
            type="richselect"
            rules="required"
            :name="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.preAuthRideCondition'
              )
            "
            :label="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.title.preAuthRideCondition'
              )
            "
            :infoDescription="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.infoDescription.preAuthRideCondition'
              )
            "
            :placeholder="
              $t(
                'components.billingPlanManagement.addEdit.steps.ride.placeHolder.preAuthRideCondition'
              )
            "
            value-attribute="value"
            text-attribute="name"
            :options="rideTypeChoice"
            hide-search-box
          />
        </div>
      </div>

      <!-- rent-packages -->
      <template v-if="isRentPackageShow">
        <div class="flex items-center mb-3 font-bold text-gray-500 mt-5">
          <span>{{
            $t(
              'components.billingPlanManagement.addEdit.steps.ride.title.rentPackages'
            )
          }}</span>
        </div>
        <section
          v-for="(rentalPackage, rentalPackageIndex) in rentalPackages"
          :key="rentalPackageIndex"
        >
          <div class="flex items-center my-5 text-sm font-bold text-gray-500 ">
            <span>{{ getRentalPackageTitle(rentalPackage.value) }}</span>
            <div
              class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
              @click="
                onAddRentalPackage(rentalPackageIndex, rentalPackage.value)
              "
            >
              <i class="fas fa-plus-circle" style="color: black"></i>
            </div>
          </div>

          <div
            v-for="(packageData, packageDataIndex) in rentalPackage.data"
            :key="packageDataIndex"
          >
            <div class="flex items-center">
              <div class="flex items-start  gap-x-1 w-11/12  ">
                <div class="w-6/12   ">
                  <AppInput
                    v-model="packageData.period"
                    type="richselect"
                    :name="
                      $t(
                        'components.billingPlanManagement.addEdit.steps.ride.title.rentalType'
                      )
                    "
                    :label="
                      $t(
                        'components.billingPlanManagement.addEdit.steps.ride.title.rentalType'
                      )
                    "
                    :infoDescription="
                      $t(
                        'components.billingPlanManagement.addEdit.steps.ride.infoDescription.rentalType'
                      )
                    "
                    :placeholder="
                      $t(
                        'components.billingPlanManagement.addEdit.steps.ride.placeHolder.rentalType'
                      )
                    "
                    value-attribute="value"
                    text-attribute="display_name"
                    :options="rentalPackageTypeOptions[rentalPackage.value]"
                    :disabled="packageData.typeDisabled"
                    hide-search-box
                  />
                </div>

                <div class="w-6/12   ">
                  <AppInput
                    v-model.number="packageData.cost"
                    type="text"
                    rules="required"
                    :name="
                      $t(
                        'components.billingPlanManagement.addEdit.steps.ride.title.rentalCost'
                      )
                    "
                    :label="
                      $t(
                        'components.billingPlanManagement.addEdit.steps.ride.title.rentalCost'
                      )
                    "
                    :infoDescription="
                      $t(
                        'components.billingPlanManagement.addEdit.steps.ride.infoDescription.rentalCost'
                      )
                    "
                    placeholder="e.g. 5"
                    :haveSuggestion="
                      parseFloat(packageData.cost) ? true : false
                    "
                    :isSuggestionAlert="
                      getSavePercentage(
                        parseFloat(packageData.period),
                        parseFloat(packageData.cost),
                        rentalPackage.value
                      ) < 0
                        ? true
                        : false
                    "
                    :suggestionText="
                      `${
                        form.tax_enabled
                          ? getVatIncludedAmount(packageData.cost)
                          : ''
                      } ${form.tax_enabled ? ', ' : ''}Save ${getSavePercentage(
                        parseFloat(packageData.period),
                        parseFloat(packageData.cost),
                        rentalPackage.value
                      )}%`
                    "
                    :haveUnitText="true"
                    :unitText="selectedFleetCurrency"
                  />
                </div>
              </div>

              <div class="flex  w-1/12 item-center justify-center">
                <div
                  class="col-span-1 cursor-pointer focus:text-gray-400"
                  @click="
                    onRemoveRentPackage(rentalPackageIndex, packageDataIndex)
                  "
                >
                  <i class="fas fa-minus-circle" style="color: #d90a20"></i>
                </div>
              </div>
            </div>
            <div class="pb-2">
              <section>
                <t-checkbox
                  wrapped
                  v-model="packageData.addDescriptionUI"
                  :name="
                    $t(
                      'components.billingPlanManagement.addEdit.steps.ride.title.addNoteCheckbox'
                    )
                  "
                  :label="
                    $t(
                      'components.billingPlanManagement.addEdit.steps.ride.title.addNoteCheckbox'
                    )
                  "
                />
              </section>

              <section v-show="packageData.addDescriptionUI">
                <AppInput
                  v-model="packageData.description"
                  rules=""
                  :name="
                    $t(
                      'components.billingPlanManagement.addEdit.steps.ride.title.notes'
                    )
                  "
                  :label="
                    $t(
                      'components.billingPlanManagement.addEdit.steps.ride.title.notes'
                    )
                  "
                  type="textarea"
                />
              </section>
            </div>
          </div>
        </section>
      </template>

      <div class="w-full ">
        <AppInput
          v-model="form.description"
          type="textarea"
          :name="
            $t(
              'components.billingPlanManagement.addEdit.steps.ride.title.billingDescription'
            )
          "
          :label="
            $t(
              'components.billingPlanManagement.addEdit.steps.ride.title.billingDescription'
            )
          "
          :infoDescription="
            $t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.billingDescription'
            )
          "
        />
      </div>

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { deepCompareObjects, xMan } from '@/utils'
import InputLabel from '@/components/form/InputLabel'
import { BillingPlanConfig } from '@/config/BillingPlanConfig'
import {
  generateRentDataModel,
  choiceMappingForPackages,
} from '@/views/pricing/add-edit-bill/helper.js'
import { useEndpoints } from '@/composables'
export default {
  name: 'Step1',

  components: {
    InputLabel,
  },

  props: {
    vehicleTypes: {
      type: Array,
      default: () => [],
    },
    fleets: {
      type: Array,
      default: () => [],
    },
    taxPlans: {
      type: Array,
      default: () => [],
    },

    primaryKey: {
      type: null,
      required: false,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTaxPlanLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRentPackageShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    rentTypeChoices: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isLoading: false,
      taxPercentage: 0,
      modifiedTaxPlan: [],
      form: {},
      rideTypeChoice: [
        {
          name: 'First Ride',
          value: 'FIRST_RIDE',
        },
        {
          name: 'All Ride',
          value: 'ALL_RIDE',
        },
      ],
      rentalPackages: [],
      rentalPackageTypeOptions: {
        //hourly
        H: [
          {
            display_name: '1/2 hour',
            value: 0.5,
          },
          {
            display_name: '1 hour',
            value: 1,
          },
          {
            display_name: '2 hours',
            value: 2,
          },
          {
            display_name: '3 hours',
            value: 3,
          },
          {
            display_name: '4 hours',
            value: 4,
          },
          {
            display_name: '5 hours',
            value: 5,
          },
          {
            display_name: '6 hours',
            value: 6,
          },
          {
            display_name: '7 hours',
            value: 7,
          },
          {
            display_name: '8 hours',
            value: 8,
          },
          {
            display_name: '16 hours',
            value: 16,
          },
        ],
        //daily
        D: [
          {
            display_name: '1 day',
            value: 1,
          },
          {
            display_name: '2 days',
            value: 2,
          },
          {
            display_name: '3 days',
            value: 3,
          },
          {
            display_name: '5 days',
            value: 5,
          },
          {
            display_name: '7 days',
            value: 7,
          },
          {
            display_name: '15 days',
            value: 15,
          },
        ],
      },
      rentalPackageChoices: choiceMappingForPackages,
      deleteRentPackages: [],
    }
  },

  computed: {
    selectedFleet() {
      return this.form.fleet
        ? this.fleets.find((fleet) => fleet.id == this.form.fleet)
        : null
    },
    selectedFleetCurrency() {
      return this.selectedFleet
        ? this.selectedFleet?.country?.currency_symbol
        : null
    },
    selectedFleetCurrencyFormatted() {
      return this.selectedFleetCurrency ? `${this.selectedFleetCurrency}` : '--'
    },
    getTaxPlanPlaceholder() {
      if (this.isTaxPlanLoading)
        return this.$t(
          'components.billingPlanManagement.addEdit.steps.ride.placeHolder.chooseTaxPlan.loading'
        )
      if (this.isTaxPlanLoading === false && this.taxPlans.length === 0)
        return this.$t(
          'components.billingPlanManagement.addEdit.steps.ride.placeHolder.chooseTaxPlan.noTaxPlan'
        )
      return this.$t(
        'components.billingPlanManagement.addEdit.steps.ride.placeHolder.chooseTaxPlan.selectTaxPlan'
      )
    },
    getTaxPlanDisabledState() {
      if (this.isTaxPlanLoading) return true
      if (this.isTaxPlanLoading === false && this.taxPlans.length === 0)
        return true
      return false
    },
  },

  watch: {
    // sync props.formData with $data.from
    'formData': {
      deep: false,
      immediate: true,
      handler(data) {
        if (data) {
          console.log('formData-b', data)
          const rentPlanData = data.rentPlanData

          this.form = {
            ...rentPlanData,
            fleet: data.rentPlanData.fleet.id || null,
            tax_plan: data.rentPlanData.tax_plan
              ? data.rentPlanData.tax_plan.id
              : null,
            pause_ride_fee_same_as_billing:
              rentPlanData.pause_ride_fee_same_as_billing === null
                ? true
                : rentPlanData.pause_ride_fee_same_as_billing,
            is_pre_auth_required:
              rentPlanData.is_pre_auth_required === null
                ? true
                : rentPlanData.is_pre_auth_required,
            tax_applies_to_pause_fees:
              rentPlanData.tax_applies_to_pause_fees === null
                ? true
                : rentPlanData.tax_applies_to_pause_fees,
          }
          this.rentalPackages = data.rentPackages
          console.log('rentalPackages-post', this.rentalPackages)
        }
      },
    },
    'taxPlans': {
      deep: false,
      immediate: true,
      handler(data) {
        this.modifiedTaxPlan = data.map((item) => {
          return {
            text: `${item.name} (${Number(item.percentage)}%)`,
            value: item.id,
          }
        })
      },
    },

    // notify form is dirty & user should confirm before exiting
    'form': {
      deep: true,
      immediate: false,
      handler(updatedFormData) {
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        this.$emit('dirty', { step: 1, data: this.form })
      },
    },
    'form.tax_plan': {
      deep: true,
      immediate: true,
      handler(updatedId) {
        if (updatedId && this.taxPlans) {
          this.taxPercentage = Number(
            this.taxPlans.find((item) => item.id === updatedId).percentage
          )
        }
      },
    },
    'rentTypeChoices': {
      deep: false,
      immediate: true,
      handler(updatedData) {
        if (updatedData.length > 0 && !this.isEditing) {
          this.rentalPackages = this.generateRentDataModel(updatedData)
        }
      },
    },
  },

  methods: {
    generateRentDataModel,
    getVatIncludedAmount(amount) {
      if (amount && this.selectedFleetCurrency) {
        const vatAmount = (Number(this.taxPercentage) * Number(amount)) / 100
        const totalAmount = Number(amount) + vatAmount
        return `${this.selectedFleetCurrency}${Number(
          totalAmount.toFixed(2)
        )} Inc. VAT`
      } else {
        return ''
      }
    },
    getRentalPackageTitle(type) {
      return this.rentalPackageChoices[type]
    },
    onAddRentalPackage(index, typeValue) {
      this.rentalPackages[index].data.unshift({
        period: 0,
        cost: 0,
        type: typeValue,
        addDescriptionUI: false,
        typeDisabled: false,
        description: '',
      })
    },
    onRemoveRentPackage(parentIndex, childIndex) {
      if (this.isEditing) {
        this.deleteRentPackages.push(
          this.rentalPackages[parentIndex].data[childIndex]
        )
      }
      this.rentalPackages[parentIndex].data.splice(childIndex, 1)
    },

    getSavePercentage(period, packageCost, type) {
      let totalHours = 0
      if (type === 'H') {
        totalHours = period
      }
      if (type === 'D') {
        totalHours = period * 24
      }

      if (
        packageCost &&
        this.form.billing_unit_mins &&
        this.form.default_unit_fees
      ) {
        const packageCostPerHour = packageCost / totalHours
        let rental_unit_fee_per_hour =
          (Number(this.form.default_unit_fees) /
            Number(this.form.billing_unit_mins)) *
          60

        const savePercentage =
          ((rental_unit_fee_per_hour - packageCostPerHour) /
            rental_unit_fee_per_hour) *
          100
        return parseFloat(savePercentage).toFixed(2)
      } else {
        return ''
      }
    },
    async submit() {
      this.$refs.submitButton.click()
    },

    async submitStep() {
      this.isLoading = true

      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? BillingPlanConfig.api.update(this.form.id)
        : BillingPlanConfig.api.create

      const formDataProxy = { ...this.form }

      if (!this.form.tax_enabled) {
        formDataProxy.tax_plan = ''
      }

      if (this.form.default_currency)
        formDataProxy.default_currency = this.form.default_currency.id

      let data = new xMan(formDataProxy).toFormData()

      await this.$http({ method, url, data })
        .then((res) => {
          this.rentPackageSubmit(res.data.id)
          let message = this.isEditing
            ? 'Billing plan updated successfully'
            : 'Billing plan added successfully'
          this.isLoading = false
          this.$emit('save', {
            step: 1,
            data: res.data,
            taxPlanEnabled: this.form.tax_enabled,
            taxPercentage: this.taxPercentage,
          })

          this.$notify(
            {
              group: 'bottomLeft',
              type: 'success',
              title: 'Success',
              text: message,
            },
            3000
          )
        })
        .catch((err) => {
          console.error('Error occured!', err.response)
          const e = err?.response.data
          const max = Object.keys(e).length
          let t = max * 2000 // in ms

          for (const k in e) {
            this.$notify(
              {
                group: 'bottomLeft',
                type: 'error',
                title: `Error Occured`,
                text: k + ': ' + e[k],
              },
              t
            )
            t -= 2000
          }
        })
        .finally(() => (this.isLoading = false))
    },
    async rentPackageSubmit(rentPlanId) {
      const requests = []
      const rentPackDataReq = []
      this.rentalPackages.forEach((rentPack) => {
        if (rentPack.data.length > 0) {
          rentPack.data.forEach((packData) => {
            const isPackDataUpdateReq = 'id' in packData
            const packDataReqMethod = isPackDataUpdateReq ? 'PATCH' : 'POST'
            const packDataReqUrl = isPackDataUpdateReq
              ? useEndpoints.pricingPlan.updateRentPackage(packData.id)
              : useEndpoints.pricingPlan.createRentPackage()
            const packDataReqData = new FormData()

            if (isPackDataUpdateReq) {
              if (packData.cost <= 0) return
              packDataReqData.append('cost', packData.cost)
              if (packData.addDescriptionUI) {
                packDataReqData.append('description', packData.description)
              } else {
                packDataReqData.append('description', '')
              }
            } else {
              if (packData.cost <= 0 || packData.period === 0) return
              packDataReqData.append('type', packData.type)
              packDataReqData.append('pricing_plan', rentPlanId)
              packDataReqData.append('period', packData.period)
              packDataReqData.append('cost', packData.cost)
              if (packData.addDescriptionUI) {
                packDataReqData.append('description', packData.description)
              } else {
                packDataReqData.append('description', '')
              }
            }

            const packDataReq = this.$http({
              url: packDataReqUrl,
              method: packDataReqMethod,
              data: packDataReqData,
            })
            rentPackDataReq.push(packDataReq)
          })
        }
      })

      //delete rent packages

      let deleteRentPackageReqs = []
      if (this.isEditing && this.deleteRentPackages.length) {
        this.deleteRentPackages.forEach((element) => {
          if ('id' in element) {
            const deleteRentReq = this.$http({
              url: useEndpoints.pricingPlan.deleteRentPackage(element.id),
              method: 'DELETE',
            })
            deleteRentPackageReqs.push(deleteRentReq)
          }
        })
      }
      if (deleteRentPackageReqs.length) {
        requests.push(...deleteRentPackageReqs)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.map-btn {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: #ff598a;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
