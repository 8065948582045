export const WhatsNewConfig = {
  api: {
    systemVersion: {
      // get
      statusChoices: () => '/dashboard/system-version-status-choices/',
      // get
      index: () => '/dashboard/system-versions/',
      // post
      create: () => '/dashboard/system-versions/',
      // get
      details: (id = 'uuid') => {
        return `/dashboard/system-versions/${id}/`
      },
      // patch
      update: (id = 'uuid') => {
        return `/dashboard/system-versions/${id}/`
      },
      //delete
      delete: (id = 'uuid') => {
        return `/dashboard/system-versions/${id}/`
      },
    },
    releaseNotes: {
      // get
      typeChoices: () => '/dashboard/version-release-note-type-choices/',
      // get
      details: (svId = 'uuid') =>
        `/dashboard/system-version/${svId}/version-release-notes/`,
      // post
      create: () => '/dashboard/version-release-notes/',
      // patch
      update: (id = 'uuid') => {
        return `/dashboard/version-release-notes/${id}/`
      },
      // delete
      delete: (id = 'uuid') => {
        return `/dashboard/version-release-notes/${id}/`
      },
    },
  },
  events: {
    name: 'whats-new',
    // refresh-index-data
    refresh: `rid-whats-new`,
    // slideover-right
    sorId: 'whats-new',
    sorOpen: 'sor-open-whats-new',
    sorClose: 'sor-close-whats-new',
    sorToggle: 'sor-toggle-whats-new',
    // editing-data
    editingData: 'edit-whats-new-data',
    // viewing-data
    viewingData: 'view-whats-new-data',
  },
}
