<template>
  <div>
    <section>
      <div class="relative">
        <div
          class="absolute top-0 left-0 flex w-10 h-full border border-transparent"
        >
          <div
            class="z-10 flex items-center justify-center w-full h-full text-lg text-gray-600 bg-gray-100 rounded-l-full"
          >
            <loading
              v-if="isLoading"
              :active="isLoading"
              :height="15"
              :opacity="0"
              :color="`#000`"
              :loader="`bars`"
              :is-full-page="false"
              :class="`outline-none`"
            />
            <div v-else>
              <div>
                <svg
                  class="w-6 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          v-if="value.length"
          class="absolute top-0  flex w-7 h-full border border-transparent"
          style="left:92%"
          @click="value = ''"
        >
          <div
            class="z-20 flex items-center justify-center w-full h-full bg-oWhite  rounded-r-full "
          >
            <img class="w-3" src="@/assets/icon/xclose.svg" alt="xclose" />
          </div>
        </div> -->

        <input
          type="search"
          placeholder="Search.."
          :value="value"
          @input="debouncedUpdate"
          @focus="focusUpdate"
          v-on:blur="blurUpdate"
          :class="
            ` relative py-1.5 pl-12 pr-2 text-sm border border-gray-300 rounded-full sm:text-base focus:border-gray-800 focus:outline-none focus:ring-0 w-96`
          "
        />
      </div>
    </section>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
export default {
  name: 'OtoSmartSearch',
  components: {},
  props: {
    value: {
      type: null,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    debouncedUpdate: debounce(function(e) {
      console.log('oss-debouncedUpdate', e)
      this.$emit('input', e.target.value)
    }, 0),
    focusUpdate: debounce(function(e) {
      console.log('oss-focusUpdate', e)
      this.$emit('focus')
    }, 400),
    blurUpdate: function() {
      setTimeout(() => {
        this.$emit('blur')
        console.log('oss-blurUpdate')
      }, 0)
    },
  },
}
</script>

<style></style>
