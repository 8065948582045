<template>
  <div>
    <STable :sId-id="resultType" :headers="getTableHeaders">
      <template v-for="(searchItem, searchItemIndex) in serverSearchResult">
        <STableRow :key="searchItemIndex" text-fallback-always>
          <STableRowItem v-if="!notShowIdItems.includes(resultType)">
            <div class="flex items-center">
              <router-link
                :to="{
                  name: getRouteForId,
                  params: { id: searchItem.id },
                }"
                :class="`text-blue-600`"
                target="_blank"
              >
                {{ searchItem.id.substr(searchItem.id.length - 5) }}
              </router-link>
            </div>
          </STableRowItem>
          <!-- trip -->
          <STableRowItem
            v-if="resultType === 'trip'"
            :to="{
              name: 'ViewRiderUserProfile',
              params: { id: searchItem.user.id },
            }"
            target="_blank"
            :text="searchItem.user.phone_number"
          />
          <STableRowItem
            v-if="resultType === 'trip'"
            :text="
              getFormattedDateTime(
                searchItem.pick_up_time,
                'D MMM, YY hh:mm:ss a'
              )
            "
          />
          <STableRowItem
            v-if="resultType === 'trip'"
            :text="
              `${searchItem.invoice.currency.symbol || '--'} ${searchItem
                .invoice.amount || '--'}`
            "
          />
          <STableRowItem v-if="resultType === 'trip'">
            <XStatus
              v-tooltip="
                getTooltipText(
                  searchItem.status,
                  searchItem.status_forced,
                  searchItem.status_forced_by_system
                )
              "
              :text="
                getUserTripStatus(
                  searchItem.status,
                  searchItem.status_forced,
                  searchItem.status_forced_by_system
                )
              "
              :variant="
                getUserTripStatusBadge(
                  searchItem.status,
                  searchItem.status_forced,
                  searchItem.status_forced_by_system
                )
              "
              :profile="`trip`"
            />
          </STableRowItem>

          <!-- vehicle -->

          <STableRowItem v-if="resultType === 'vehicle'">
            <div class="flex items-center">
              <router-link
                :to="{
                  name: getRouteForId,
                  params: { id: searchItem.id },
                }"
                :class="`text-blue-600`"
                target="_blank"
              >
                {{ searchItem.qr_code }}
              </router-link>
            </div>
          </STableRowItem>

          <STableRowItem
            v-if="resultType === 'vehicle'"
            :text="searchItem.lock.lock_id"
          />
          <STableRowItem v-if="resultType === 'vehicle'">
            <span
              v-text="
                getUTCAwareTime(searchItem.last_connected_at, {
                  relative: true,
                })
              "
              :title="getUTCAwareTime(searchItem.last_connected_at)"
            />
          </STableRowItem>
          <STableRowItem v-if="resultType === 'vehicle'">
            <template v-if="getEnabledFlags(searchItem).length > 0">
              <div class="flex items-center">
                <template
                  v-for="(flag, flagIndex) in getEnabledFlags(searchItem)"
                >
                  <vehicle-flag-icon
                    v-tooltip.bottom="getFlagAlias(flag, searchItem)"
                    size="sm"
                    class="mr-1"
                    :name="flag"
                    :key="`vehicle-${itemIndex}-flag-${flagIndex}`"
                  />
                </template>
              </div>
            </template>
            <template v-else>
              No Flags
            </template>
          </STableRowItem>
          <STableRowItem v-if="resultType === 'vehicle'">
            <VehicleStatus
              :data="searchItem"
              :vehicle-type="`scooter`"
              :isShowDropDown="false"
              :current-status="searchItem.lock.is_locked"
              :hibernateStatus="searchItem.general_flags.hibernate_mode"
            />
          </STableRowItem>

          <!-- rental -->
          <STableRowItem
            v-if="resultType === 'rental'"
            :to="{
              name: 'ViewRiderUserProfile',
              params: { id: searchItem.rider.id },
            }"
            target="_blank"
            :text="searchItem.rider.phone_number"
          />
          <STableRowItem
            v-if="resultType === 'rental'"
            :text="
              getFormattedDateTime(
                searchItem.start_time,
                'D MMM, YY hh:mm:ss a'
              )
            "
          />
          <STableRowItem
            v-if="resultType === 'rental'"
            :text="getCostLine(searchItem)"
          />
          <STableRowItem v-if="resultType === 'rental'">
            <XStatus
              :text="getRentStatus(searchItem)"
              :variant="getRentStatusBadge(searchItem)"
              :profile="`trip`"
              v-if="!shouldShowStatusTooltip(searchItem.status)"
            />
            <XStatus
              :text="getRentStatus(searchItem)"
              :variant="getRentStatusBadge(searchItem)"
              :profile="`trip`"
              v-if="shouldShowStatusTooltip(searchItem.status)"
              v-tooltip.bottom="getTooltipTextForRent(searchItem)"
            />
          </STableRowItem>

          <!-- revenue -->

          <STableRowItem v-if="resultType === 'revenue'" text-fallback-always>
            <router-link
              :to="{
                name: searchItem.rental ? 'VehicleRentalDetail' : 'ViewTrip',
                params: {
                  id: `${
                    searchItem.rental ? searchItem.rental : searchItem.trip
                  }`,
                },
              }"
              :class="`text-blue-600`"
              target="_blank"
            >
              {{ searchItem.id.substr(searchItem.id.length - 5) }}
            </router-link>
          </STableRowItem>
          <STableRowItem
            v-if="resultType === 'revenue'"
            :text="searchItem.date"
            :date="true"
          />
          <STableRowItem
            v-if="resultType === 'revenue'"
            :text="getPaymentForText('revenue', searchItem.payment_for)"
          />
          <STableRowItem
            v-if="resultType === 'revenue'"
            :text="searchItem"
            as-currency
          />
          <STableRowItem v-if="resultType === 'revenue'">
            <div
              class="refund-action"
              :class="searchItem.is_refunded ? 'refunded' : 'refund'"
            >
              {{ searchItem.is_refunded ? 'Refunded' : 'Refund' }}
            </div>
          </STableRowItem>

          <!-- payment -->

          <STableRowItem v-if="resultType === 'payment'" text-fallback-always>
            {{ searchItem.id.substr(searchItem.id.length - 5) }}
          </STableRowItem>
          <STableRowItem
            v-if="resultType === 'payment'"
            :text="searchItem.date"
            :date="true"
          />
          <STableRowItem
            v-if="resultType === 'payment'"
            :text="getPaymentForText('payment', searchItem.payment_for)"
          />
          <STableRowItem
            v-if="resultType === 'payment'"
            :text="searchItem"
            as-currency
          />
          <STableRowItem v-if="resultType === 'payment'">
            <div
              class="refund-action"
              :class="searchItem.is_refunded ? 'refunded' : 'refund'"
            >
              {{ searchItem.is_refunded ? 'Refunded' : 'Refund' }}
            </div>
          </STableRowItem>

          <!-- rider -->
          <STableRowItem v-if="resultType === 'rider'">
            <router-link
              :to="{
                name: 'ViewRiderUserProfile',
                params: { id: searchItem.id },
              }"
              :class="`text-blue-600`"
              target="_blank"
            >
              <div class="flex gap-1">
                <span v-tooltip.bottom="searchItem.last_ip_address">{{
                  searchItem.full_name
                }}</span>
                <img
                  v-if="searchItem.is_ip_blocked"
                  v-tooltip.bottom="`Blacklisted`"
                  class="w-4 h-4 "
                  src="@/assets/icon/blacklist_user.svg"
                />
              </div>
            </router-link>
          </STableRowItem>
          <STableRowItem
            v-if="resultType === 'rider'"
            :text="searchItem.phone_number"
          />
          <STableRowItem v-if="resultType === 'rider'">
            <div v-if="searchItem.default_card">
              <i :class="getPaymentCardIconClass(searchItem.default_card)"></i>
              <span class="ml-1">**{{ searchItem.default_card.number }}</span>
            </div>
            <div v-else>--</div>
          </STableRowItem>
          <STableRowItem v-if="resultType === 'rider'">
            {{ symbol }}
            {{ parseFloat(searchItem.balance).toFixed(2) }}
          </STableRowItem>
          <STableRowItem v-if="resultType === 'rider'">
            <x-status
              :text="getRiderTripStatus(searchItem.last_trip_status)"
              :variant="getRiderTripStatusBadge(searchItem.last_trip_status)"
              profile="trip"
            />
          </STableRowItem>
        </STableRow>
      </template>
    </STable>
  </div>
</template>
<script>
import { STable, STableRow, STableRowItem } from '@/components/s-table'
import { getFormattedDateTime } from '@/utils/datetime'
import {
  TableHeaders,
  RouteSources,
  getUserTripStatusBadge,
  getTooltipText,
  getUserTripStatus,
  getEnabledFlags,
  getFlagAlias,
  getCostLine,
  shouldShowStatusTooltip,
  getRentStatus,
  getRentStatusBadge,
  getTooltipTextForRent,
  getPaymentForText,
  getPaymentCardIconClass,
  getRiderTripStatus,
  getRiderTripStatusBadge,
} from '@/resources/SmartSearchResource.js'
import { getUTCAwareTime } from '@/utils'
export default {
  name: 'ServerSearchResult',
  components: {
    XStatus: () => import('@/components/badge/XStatus'),
    VehicleFlagIcon: () => import('@/components/badge/VehicleFlagIcon'),
    VehicleStatus: () => import('@/composites/vehicle/shared/MoreActions.vue'),
    STable,
    STableRow,
    STableRowItem,
  },
  props: {
    serverSearchResult: {
      type: Array,
      default: () => [],
    },
    resultType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      notShowIdItems: ['vehicle', 'revenue', 'payment', 'rider'],
      symbol: '--',
    }
  },
  async created() {
    this.symbol = this?.$org?.default_currency?.symbol || '$'
  },
  computed: {
    getTableHeaders() {
      return TableHeaders[this.resultType]
    },
    getRouteForId() {
      return RouteSources[this.resultType]
    },
  },
  mounted() {},
  methods: {
    getFormattedDateTime,
    getUserTripStatusBadge,
    getTooltipText,
    getUserTripStatus,
    getUTCAwareTime,
    getEnabledFlags,
    getFlagAlias,
    getCostLine,
    shouldShowStatusTooltip,
    getRentStatus,
    getRentStatusBadge,
    getTooltipTextForRent,
    getPaymentForText,
    getPaymentCardIconClass,
    getRiderTripStatus,
    getRiderTripStatusBadge,
  },
}
</script>
<style lang="scss" scoped>
.refund-action {
  color: #ffffff;
  width: 87px;
  height: 25px;
  padding: 2px;
  font-weight: 400;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;

  &.refund {
    background-color: #ff5500;
  }

  &.refunded {
    color: #5f72bf;
    background-color: #d7ebff;
  }

  &.disabled {
    background-color: #bebebe;
    color: #ffffff;
  }
}
</style>
